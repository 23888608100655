import './styles.css'
import infocomService from '../../services/Infocom';
import {Breadcrumb, Button, Card, Row, Col, Form, Icon, Layout, Input, Table, Modal, Tag, DatePicker, Divider, Popconfirm, message} from 'antd'
import React from 'react'
import fileDownload from 'js-file-download'
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-BR');

const { MonthPicker } = DatePicker;
const Column = Table;
const Content = Layout
const FormItem = Form.Item

const service = infocomService

const msgTime = 5


class InformacoesComunsInput extends React.Component {
    state = {
        records: [],

        visibleModalProtocolo: false,
        currentRecordId: null,
        currentProtocolo: "",
    }

    componentDidMount(){
        this.getAll();
    }


    getAll = (cancelados=false) => {
        service.getAll()
        .then(response => {
            let records = response.data
            if(!cancelados)
                records = records.filter(e => e.status!=="Cancelado")
            this.setState({records});
          })
          .catch(err => {
                this.setState({
                    records: []
                });
                message.error('Não foi possível obter os registros.', msgTime);
                console.error('Não foi possível obter os registros.', err)
          })
    }


    handleSubmit = (event) => {
        console.group()
        console.debug("handleSubmit:start")
        event.preventDefault();

        var data = this.props.form.getFieldsValue();
        data.mesFim = data.mesFim.format('YYYYMM')
        data.mesInicio = data.mesInicio.format('YYYYMM')

        this.setState({ loading: true });
        service.save(data).then(response => {
            console.debug("handleSubmit:callback")
            this.setState({ loading: false });
            message.success('Registro salvo com sucesso', msgTime);
            this.getAll()
            console.debug("handleSubmit:callback:finished")
            console.groupEnd()
        })
        .catch(error => {
            console.debug("handleSubmit:error:callback")
            this.setState({ loading: false })
            const msg = error.response.data.error || 'Não foi possível salvar o registro'
            message.error(msg, msgTime)
            console.error(msg)
            console.debug("handleSubmit:error:finished")
            console.groupEnd()
        });

        // this.setState({ loading: false });
        console.debug("handleSubmit:end")
    }


    cancelRecord = (id) => {
        console.log('CANCELING:', id)
        service.cancel(id).then(response => {
            message.success('Registro cancelado com sucesso.', msgTime);
            this.getAll()
          })
          .catch(err => {
                message.error('Não foi possível cancelar o registro.', msgTime);
                console.error('Não foi possível cancelar o registro.', err)
          })
    }


    sendProtocol = () => {
        const id = this.state.currentRecordId
        let param = { "protocolo": this.state.currentProtocolo }
        service.setProtocol(id, param).then(response => {
            message.success('Protocolo salvo com com sucesso.', msgTime);
            this.hideModal()
            this.getAll()
        })
        .catch(err => {
            message.error('Não foi possível alterar o protocolo.', msgTime);
            console.error('Não foi possível alterar o protocolo.', err)
          })
    }


    // TODO: WIP
    downloadRecord = (row) => {
        service.download(row.id).then(response => {
            message.success('Arquivo gerado com sucesso.', msgTime);
            let nomeArquivo = 'INFOCOM_' + String(row.cnpj).replace('/','').replace('-','').replace(/\./g, '') + '_' + String(row.mes_inicio).replace('/','') + '.txt'
            fileDownload(response.data, nomeArquivo, 'application/octet-stream')
          })
          .catch(err => {
                message.error('Não foi possível gerar os registros.', msgTime);
                console.error('Não foi possível gerar os registros.', err);
          })
    }


    showModalProcotolo = (record) => {
        this.setState({
            visibleModalProtocolo: true,
            // currentProtocolo: record.protocolo,
            currentRecordId: record.id
        });
    }

    hideModal = () => {
        this.setState({
            visibleModalProtocolo: false,
            currentProtocolo: "",
            currentRecordId: null
        });
    }


    renderAction = (record) => {
        const {status, id} = record
        if (status !== "Cancelado")
            return (
            <div>
                {/* <Button onClick={() => {this.showModalProcotolo(id)}} type="dashed" title="Adicionar protocolo" alt="Adicionar protocolo" icon="file-ppt" style={{marginRight: 5}} /> */}
                {/* <Button onClick={() => {this.showModalCancelar(id)}} type="dashed" title="Cancelar" icon="close" style={{marginRight: 5}} /> */}
                {/* <Button onClick={() => {this.downloadRecord(id)}} type="dashed" title="Download Informações Comuns" icon="download" /> */}

                <Button onClick={() => {this.downloadRecord(record)}} type="dashed" title="Download Informações Comuns" icon="download" />

                <Button onClick={() => {this.showModalProcotolo(record)}} disabled={status!=="Importado"} type="dashed" title="Adicionar protocolo" alt="Adicionar protocolo" icon="file-ppt" style={{marginRight: 5}} />

                { status==="Protocolado" ? (
                    <Button disabled type="dashed" title="Cancelar" icon="close" style={{marginRight: 5}} />
                    ) : (
                    <Popconfirm
                        title='Deseja realmente cancelar?'
                        okText='Sim' cancelText='Não'
                        onConfirm={() => this.cancelRecord(id)}
                    >
                        <Button type="dashed" title="Cancelar" icon="close" style={{marginRight: 5}} />
                    </Popconfirm>
                )}

            </div>
            )
    }



    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        const {records, currentProtocolo} = this.state
        const monthFormat = 'MM / YYYY';
        const dayFormat = 'DD / MM / YYYY';

        return (
            <Content className='custom-content' style={{ background: '#fff', padding: '24px' }}>
                <h1>Informações Comuns</h1>
                <Breadcrumb style={{ marginBottom: '12px' }}>
                    <Breadcrumb.Item>
                        <Icon type='home' />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Informações Comuns</Breadcrumb.Item>
                </Breadcrumb>
                <Card className='custom-card'>

                    <Form onSubmit={this.handleSubmit}>

                        <Row>
                            <Col span={8}>
                                <FormItem {...formItemLayout} label='Mês do Inicio'>
                                {
                                    getFieldDecorator('mesInicio', {
                                                initialValue: moment().startOf('year'),
                                                rules: [{ required: true, message: 'Data de início inválido' }]
                                            })(
                                    <MonthPicker
                                        placeholder='DD / AAAA'
                                        allowClear={false}
                                        format={monthFormat}
                                        />)
                                }
                                </FormItem>
                            </Col>

                            <Col offset={2} span={8}>
                                <FormItem {...formItemLayout} label='Mês do Fim'>
                                {
                                    getFieldDecorator('mesFim', {
                                                initialValue: moment().endOf('year'),
                                                rules: [{ required: true, message: 'Data de fim inválido' }]
                                            })(
                                    <MonthPicker
                                        placeholder='DD / AAAA'
                                        allowClear={false}
                                        format={monthFormat}
                                        />)
                                }
                                </FormItem>
                            </Col>
                            <Col offset={2} span={2}>
                                <FormItem>
                                    <Button type='primary' icon='plus' htmlType='submit'>Criar</Button>
                                </FormItem>
                            </Col>
                        </Row>

                    </Form>

                    <Divider />

                    <Card className='custom-card'>

                        {/* TODO: Create filters */}
                        <Table dataSource={records} rowKey='id'>
                            <Column title='Inicio' dataIndex='mes_inicio' key='mes_inicio'
                                render={ r => moment(new Date(r)).format(monthFormat)} />
                            <Column title='Fim' dataIndex='mes_fim' key='mes_fim'
                                render={ r => moment(new Date(r)).format(monthFormat)} />
                            <Column title='Data de Processamento' dataIndex='data_criacao' key='data_criacao'
                                render={ r => moment(r).format(dayFormat)} />
                            <Column title='Protocolo' dataIndex='protocolo' key='protocolo' />
                            <Column title='Status' dataIndex='status' key='status'
                                render={status => <Tag color='green'>{status}</Tag>} />
                            <Column
                                defaultSortOrder='descend'
                                sorter={(a, b) => a.id - b.id}
                                render={record => this.renderAction(record)}
                            />
                        </Table>

                </Card>



                <Modal title="Protocolo"
                    visible={this.state.visibleModalProtocolo}
                    onOk={this.sendProtocol}
                    onCancel={this.hideModal}
                    okText="Protocolar"
                    cancelText="Cancelar"
                    >
                    <Input placeholder="Protocolo"
                        value={currentProtocolo}
                        onChange={evt => this.setState({currentProtocolo: evt.target.value})}
                    />
                </Modal>

                </Card>
            </Content>
        )

    }

}


const WrappedInfocomForm = Form.create()(InformacoesComunsInput);

export default WrappedInfocomForm
