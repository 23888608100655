import Http from '../utils/Http';

const GenericService = (service) => {
    return {

        getAll() {
            return Http.get_fixed(`/${service}`);
        },

        get(id) {
            return Http.get_fixed(`/${service}/${id}`);
        },

        save(data) {
            return Http.post_fixed(`/${service}`, data);
        },

        update(id, data) {
            return Http.put_fixed(`/${service}/${id}`, data);
        },

        delete(id) {
            return Http.delete_fixed(`/${service}/${id}`);
        },

        cancel(id) {
            return Http.delete_fixed(`/${service}/${id}`);
        },

        download(id) {
            return Http.download(`/${service}/gerar/${id}`);
        },

        setProtocol(id, data) {
            return Http.put_fixed(`/${service}/protocolo/${id}`, data);
        },

        setRectification(id, data) {
            return Http.put_fixed(`/${service}/retificao/${id}`, data);
        },

    }
}

export default GenericService
