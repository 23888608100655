import React from 'react'
import { Breadcrumb, Button, message, Card, Row, Form, Icon, Select, Col, Layout, Table, Modal, Tag , Input, DatePicker} from 'antd'
import moment from 'moment';
import fileDownload from 'js-file-download'
import Http from '../../utils/Http'
import EmpresaSelector from '../Widgets/EmpresaSelector'
import './styles.css'
import 'moment/locale/pt-br';

const { MonthPicker } = DatePicker;
const Column = Table;
const Content = Layout
const FormItem = Form.Item
const Option = Select
moment.locale('pt-BR');

class ApuracaoMensalISSQNView extends React.Component {
    state = {
        resultado: [],
        empresas: [],
        visibleModalProtocolo: false,
        visibleModalCancelar: false,
        visibleModalRetificacao: false,
        uploading: false,
        currentProtocolo: "",
        currentApuracaoId: "",
        currentRetificacao: "",
        mesReferencia: "01/2019",
        idEmpresa: ""
    }

    componentDidMount(){
        this.buscarApuracoes();
        this.buscarEmpresas();
    }

    atualizarRetificacao = (e) => {
        this.setState({currentRetificacao: e.target.value})
    }

    atualizarProtocolo = (e) => {
        this.setState({currentProtocolo: e.target.value})
    }

    buscarEmpresas = () => {
        Http.get("/empresa/usuario").then(response => {
            const lista = response.data.map((empresa) =>
                <Option key={empresa.id} value={empresa.id}>
                    {empresa.nome_fantasia} - {empresa.ni}
                </Option>)
            this.setState({ listaEmpresas: lista })
          })
          .catch(err => {
                this.setState({
                    empresas: []
                });
                message.error('Não foi possível obter as empresas.');
                console.error('Não foi possível obter as empresas.', err)
          })
    }

    downloadApuracao = (row) => {
        Http.download("/apuracao/gerar/" + row.id, {}).then(response => {
            message.success('Arquivo gerado com sucesso.');
            // let nomeArquivo = 'ISSQN_MENSAL_' + String(row.cnpj).replace('/','').replace('-','').replace(/\./g, '') + '_' + String(row.mesano).replace('/','') + '.txt'
            let nomeArquivo = `APURACAO_MENSAL_${row.mes_referencia.replace('/','')}.txt`
            fileDownload(response.data, nomeArquivo, 'application/octet-stream')
          })
          .catch(err => {
                message.error('Não foi possível gerar a apuração.');
          })
    }

    enviarProtocolo = () => {
        let param = {
            "apuracao_id": this.state.currentApuracaoId,
            "protocolo": this.state.currentProtocolo
        }
        Http.post("/apuracao/protocolo", param).then(response => {
            message.success('Protocolo salvo com com sucesso.');
            this.hideModal()
            this.buscarApuracoes()
          })
          .catch(err => {
                message.error('Não foi possível alterar o protocolo.');
                console.error('Não foi possível alterar o protocolo.', err)
          })
    }

    enviarRetificao = () => {
        let param = {
            "apuracao_id": this.state.currentApuracaoId,
            "codigo_retificacao": this.state.currentRetificacao
        }
        Http.post("/apuracao/retificar", param).then(response => {
            message.success('Código da retificação atualizado com com sucesso.');
            this.hideModal()
            this.buscarApuracoes()
          })
          .catch(err => {
                message.error('Não foi possível alterar o código da retificação.');
                console.error('Não foi possível alterar o código da retificação.', err)
          })
    }


    cancelarApuracao = () => {
        let param = {
            "apuracao_id": this.state.currentApuracaoId
        }
        Http.post("/apuracao/cancelar", param).then(response => {
            message.success('Apuração cancelada com sucesso.');
            this.hideModal()
            this.buscarApuracoes()
          })
          .catch(err => {
                message.error('Não foi possível cancelar a apuração.');
                console.error('Não foi possível cancelar a apuração.', err)
          })
    }

    showModalRetificacao = (row) => {
        console.log('show row', row)
        this.setState({
            visibleModalRetificacao: true,
            currentRetificacao: row.codigoretificacao,
            currentApuracaoId: row.id
        });
    }

    showModalProcotolo = (row) => {
        this.setState({
            visibleModalProtocolo: true,
            currentProtocolo: row.protocolo,
            currentApuracaoId: row.id
        });
    }

    showModalCancelar = (row) => {
    this.setState({
        visibleModalCancelar: true,
        currentApuracaoId: row.id
    });
    }

    selecionarEmpresa = (value) => {
        this.setState({idEmpresa: value});
    }

    filtrarDados = (value) => {
        console.log('filtro', value)
        // eslint-disable-next-line eqeqeq
        this.buscarApuracoes(value==1)
    }



    gerarApuracao = () => {
        const { idEmpresa, mesReferencia } = this.state;
        if (idEmpresa === undefined || idEmpresa.length === 0){
            message.error('Selecione ao menos uma empresa.');
            return
        }

        this.setState({uploading: true});

        let data = {
            "idEmpresa": idEmpresa,
            "mesReferencia": mesReferencia
        }

        Http.post("/apuracao", data).then(response => {
            message.success('Apuração salva com com sucesso.');
            this.setState({uploading: false});
            setTimeout(function () { window.location.href = "/apuracao-mensal-issqn"; }, 3000);
          })
          .catch(err => {

                this.setState({uploading: false});
                message.error('Não foi possível criar a apuração.' +  String(err.response.data.error));
                console.error('Não foi possível criar a apuração.', err)
          })
    }

    hideModal = () => {
        this.setState({
            visibleModalProtocolo: false,
            visibleModalCancelar: false,
            visibleModalRetificacao: false,
            currentProtocolo: "",
            currentApuracaoId: ""
        });
    }

    selecionarMesReferencia = (value) => {
        if(value){
          let data_final = '' + String(value._d.getUTCMonth() + 1).padStart(2, '0') + '/' + value._d.getUTCFullYear();
          this.setState({mesReferencia: data_final});
        }
    }

    renderAction = (status, row) => {
        if (row.status !== "Cancelado"){
            return (
            <div key='action'>
                <Button onClick={() => {this.showModalProcotolo(row)}} type="dashed" title="Adicionar protocolo" alt="Adicionar protocolo" icon="file-ppt" style={{marginRight: 5}} />
                <Button onClick={() => {this.showModalCancelar(row)}} type="dashed" title="Cancelar" icon="close" style={{marginRight: 5}} />
                <Button onClick={() => {this.downloadApuracao(row)}} type="dashed" title="Download Apuração" icon="download" />
                <Button onClick={() => {this.showModalRetificacao(row)}} type="dashed" title="Adicionar retificação" alt="Adicionar retificação" icon="snippets" style={{marginRight: 5}} />
            </div>
            )}
    }

    buscarApuracoes = (canceladas=false) => {
        this.setState({loading: true})
        Http.get('/apuracao').then(response => {
            let resultado = response.data
            if(!canceladas)
                resultado = resultado.filter(e =>
                    !["Cancelado", "Importado"].includes(e.status)
                )
            this.setState({resultado, loading: false});
          })
          .catch(err => {
                this.setState({
                    resultado: [],
                    loading: false,
                });
                message.error('Não foi possível obter a apuração.');
                console.error('Não foi possível obter a apuração.', err)
          })
    }


    render() {
        const {resultado, currentProtocolo, uploading, currentRetificacao} = this.state
        const monthFormat = 'MM/YYYY';

        return (
            <Content className='custom-content' style={{ background: '#fff', padding: '24px' }}>
                <h1>Apuração Mensal ISSQN</h1>
                <Breadcrumb style={{ marginBottom: '12px' }}>
                    <Breadcrumb.Item>
                        <Icon type='home' />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Apuração Mensal ISSQN</Breadcrumb.Item>
                </Breadcrumb>
                {/* <Card> */}
                    <Row>
                        <Col span={7} >
                            <FormItem label='Empresa'>
                                <EmpresaSelector
                                    onChange={value => this.setState({idEmpresa: value})}
                                />
                            </FormItem>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={12}>
                            <FormItem  label='Mês/Ano de Referência'>
                                <MonthPicker onChange={(value) => this.selecionarMesReferencia(value)}
                                    placeholder='DD/AAAA'
                                    allowClear={false}
                                    defaultValue={moment('01/2019', monthFormat)} format={monthFormat} />

                                <Button style={{marginLeft:15}} onClick={() => this.gerarApuracao()}
                                    type='primary'
                                    icon='plus'
                                    loading={uploading}
                                    htmlType='submit' >Gerar</Button>
                            </FormItem>

                        </Col>
                        {/* <Col style={{marginTop: '4%'}} span={4}>
                                <Button type='primary' icon='plus' htmlType='submit' style={{ marginBottom: '12px' }}>Gerar</Button>
                        </Col> */}
                        {/* <Col style={{backgroundColor: 'gray'}} span={6}></Col> */}
                    </Row>

                    <Row>
                        <Col span={12} >
                            <FormItem  label='Status da apuração:'>
                                <Select defaultValue={''} style={{width:'50%'}}  onChange={(value) => this.filtrarDados(value)}>
                                    <Option value=''>Normal</Option>
                                    <Option value='1'>Normal e Canceladas</Option>
                                    {/*<Option value='2'>Empresa 2</Option> */}
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                {/* </Card> */}
                <Card className='custom-card'>
                    {/* <Link to='/apuracao-mensal-issqn/adicionar'>
                        <Button type='primary' icon='plus' htmlType='submit' style={{ marginBottom: '12px' }}>Adicionar</Button>
                    </Link> */}

                    <Table dataSource={resultado} rowKey='id'>
                        <Column title='Mês/Ano' dataIndex='mes_referencia' key='mes_referencia' />

                        <Column title='Data de Processamento' dataIndex='data_criacao' key='data_criacao'
                            render={d => moment(d).format('DD/MM/YYYY HH:MM')}/>
                        <Column title='Empresa' dataIndex='empresa.nome_empresarial' key='empresa' />
                        {/* <Column title='Cidade' dataIndex='empresa.cidade' key='cidade' /> */}
                        <Column title='CNPJ' dataIndex='empresa.ni' key='cnpj' 
                            render={cnpj => cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')}/>
                        <Column title='Protocolo' dataIndex='protocolo' key='protocolo' />
                        <Column title='Status' dataIndex='status' key='status'
                            render={status => <Tag color='green'>{status}</Tag>}
                        />
                        <Column dataIndex='id'
                                defaultSortOrder='descend'
                                sorter={(a, b) => a.id - b.id}
                                render={this.renderAction}
                        />
                    </Table>

                </Card>

                <Modal title="Protocolo"
                    visible={this.state.visibleModalProtocolo}
                    onOk={this.enviarProtocolo}
                    onCancel={this.hideModal}
                    okText="Protocolar"
                    cancelText="Cancelar"
                    >
                    <Input placeholder="Protocolo" value={currentProtocolo} onChange={(value) => this.atualizarProtocolo(value)} />
                </Modal>
                <Modal title="Atenção:"
                    visible={this.state.visibleModalCancelar}
                    onOk={this.cancelarApuracao}
                    onCancel={this.hideModal}
                    okText="Cancelar apuração"
                    cancelText="Fechar"
                    >
                    <p>Deseja realmente cancelar?</p>
                </Modal>
                <Modal title="Código Retificação"
                    visible={this.state.visibleModalRetificacao}
                    onOk={this.enviarRetificao}
                    onCancel={this.hideModal}
                    okText="Retificar"
                    cancelText="Cancelar"
                    >
                    <Input placeholder="Retificacao" value={currentRetificacao} onChange={(value) => this.atualizarRetificacao(value)} />
                </Modal>
            </Content>
        )

    }

}

export default ApuracaoMensalISSQNView
