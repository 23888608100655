import axios from 'axios';
import FormData from 'form-data';

// var apiURL = 'http://desif3.ikeep.com.br:8888';
var apiURL = 'http://localhost:8888';

console.log("process.env", process.env)

if (process.env.REACT_APP_API_URL)
    apiURL = process.env.REACT_APP_API_URL
else {
    let apiURL_ = new URL(apiURL)
    apiURL_.hostname = document.location.hostname
    apiURL_.protocol = document.location.protocol
    if (document.location.protocol === "https:") apiURL_.port = 8899;
    apiURL = apiURL_.href
}


class Http {

    constructor() {
        let axiosConfiguration = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            timeout: 300000
        };
        axiosConfiguration['baseURL'] = apiURL;
        this.axios = axios.create(axiosConfiguration);
        this.configureInterceptors();
        this.requestInterceptor = {};
    }

    configureInterceptors = () => {
        this.axios.interceptors.request.use(this.requestConfig);
        this.axios.interceptors.response.use(res => res, this.responseErrorHandler);
    }

    download(path, params) {
        return this.axios({
            headers: {
                'Accept': 'text/plain'
            },
            method: 'get',
            params: params,
            responseType: 'blob',
            url: path,
            withCredentials: false
        });
    }

    get(path, params) {
        return this.axios.get(path, { params });
    }
    get_fixed(path, data, params) {
        return this.axios.get(path, data, {params});
    }


    post(path, params) {
        return this.axios.post(path, { params });
    }
    post_fixed(path, data, params) {
        return this.axios.post(path, data, {params});
    }

    put(path, params) {
        return this.axios.put(path, { params });
    }
    put_fixed(path, data, params) {
        return this.axios.put(path, data, {params});
    }

    delete(path, params) {
        return this.axios.delete(path, { data: params });
    }
    delete_fixed(path, params) {
        return this.axios.delete(path, {params});
    }

    postFile(path, key, file, json) {
        var formData = new FormData();
        formData.append(key, file);
        if (json) {
            const jsonObj = JSON.stringify(json);
            const blob = new Blob([jsonObj], {
                type: 'application/json'
            });
            formData.append("data", blob);
        }
        return this.axios.post(
            path,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    }

    requestConfig = (config) => {
        config.headers.authorization = 'Bearer ' + window.localStorage.getItem('clientToken');
        return Promise.resolve(config);
    }

    responseErrorHandler = (error) => {
        if (!error.response) {
            throw error;
        }
        if (error.response.status === 401 || error.response.status === 403) {
            console.log("HTTP: 401/403 loggin out")
            window.location.href = "/sair"
            return
        }
        return Promise.reject(error);
    }

}

export default new Http();
