import Http from '../utils/Http';

export default {

    delete(id) {
        var params = { 'id': id };
        return Http.delete('/empresa', params);
    },

    findAll() {
        return Http.get('/empresa');
    },

    findByID(id) {
        return Http.get('/empresa/' + id);
    },

    findByUser() {
        return Http.get('/empresa/usuario');
    },

    save(empresa) {
        return Http.post('/empresa', empresa);
    },

    update(empresa) {
        return Http.put('/empresa');
    }

}
