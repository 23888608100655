import './styles.css';
import service from '../../services/PlanoConta';
import generalService from '../../services/General';
import { Breadcrumb, Button, Card, Col, Form, Icon, Input, InputNumber, Select, Layout, Row, Switch, Collapse, notification } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const Content = Layout
const FormItem = Form.Item
const Search = Input.Search

// TODO: Finish this
class PlanoContaInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            planoConta: {},
            tributavel: false,
            contasCosif: [],
            uploading: false
        }
    }

    componentDidMount() {
        console.info("PlanoConta::componentDidMount:start");
        this.setState({ loading: true });

        this.general = generalService.getGeneral();
        this.getPlanoConta();
        this.getContasCOSIF();

        this.setState({ loading: false });
    }

    getPlanoConta() {
        const idPlanoConta = this.props.match.params.id;
        console.info("idPlanoConta: ", idPlanoConta);

        if (idPlanoConta && idPlanoConta !== 'adicionar') {
            service.findByID(idPlanoConta)
            .then(response => {
                let planoConta = response.data;
                if (planoConta) {
                    this._getCosifSuperior(planoConta)
                    console.info("getPlanoConta: Setting:", planoConta)
                    this.setState({
                        disabledFields: true,
                        planoConta: planoConta,
                        tributavel: Boolean(planoConta.codigo_tributacao),
                        maskedInputClass: 'ant-input ant-input-disabled'
                    });
                }
            })
            .catch (error => {
                console.error(error);
                notification.error({
                    message: 'Plano de conta não encontrada',
                    placement: 'bottomRight'
                });
                setTimeout(function () { window.location.href = "/plano-conta"; }, 1500);
            })
        }
    }

    _getCosifSuperior = (pc) => {
        const general = this.general
        const cosif = pc.codigo_conta_cosif
        const superior = general.conta_cosif[cosif].superior
        const nome = superior ? general.conta_cosif[superior].nome : null
        pc.codigo_conta_cosif_superior = superior
        pc.codigo_conta_cosif_superior_desc = nome
    }

    getContasCOSIF = async () => {
        const general = this.general
        if (!general) {
            notification.error('Erro ao pegar contas Cosif')
            throw new Error('No general data')
        }
        const list = Object.values(general.conta_cosif).map( (conta) =>
        <Select.Option key={conta.codigo} value={conta.codigo}>{conta.codigo} - {conta.nome}</Select.Option>
        )
        this.setState({ contasCosif: list })
    }

    goUp = () => {
        const new_href = window.location.href.split("/").slice(0,-1).join("/")
        window.location.href = new_href
    };

    handleSubmit = (event) => {
        console.group()
        console.debug("handleSubmit:start")
        event.preventDefault();

        var data = this.props.form.getFieldsValue();
        this.setState({ loading: true });

        const id = this.props.match.params.id;
        let promise;
        if (!id)
            promise = service.save(data);
        else
            promise = service.update(id, data);
        promise.then(response => {
            console.debug("handleSubmit:callback")
            this.setState({ loading: false });
            notification.success({
                message: 'Registro salvo com sucesso',
                placement: 'bottomRight'
            });
            setTimeout(this.goUp, 3000);
            console.debug("handleSubmit:callback:finished")
            console.groupEnd()
        })
        .catch(error => {
            console.debug("handleSubmit:error:callback")
            this.setState({ loading: false });
            let message = 'Não foi possível salvar o registro';
            if (error.response && error.response.data && error.response.data.body && typeof error.response.data.body === 'string') {
                message = error.response.data.body;
            }
            notification.error({
                message,
                placement: 'bottomRight'
            });
            console.debug("handleSubmit:error:finished")
            console.groupEnd()
        });
        // this.setState({ loading: false });
        console.debug("handleSubmit:end")
    }


    render() {

        const { getFieldDecorator } = this.props.form;
        const { planoConta } = this.state;

        // const formItemLayout = {
        //     labelCol: {
        //         xs: { span: 24 },
        //         sm: { span: 8 }
        //     },
        //     wrapperCol: {
        //         xs: { span: 24 },
        //         sm: { span: 16 }
        //     }
        // };

        // eslint-disable-next-line no-unused-vars
        const desdobramentoFormater = new Intl.NumberFormat([], {"minimumIntegerDigits": 2})

        return (
            <Content className='custom-content' style={{ background: '#fff', padding: '24px' }}>
                <h1>Plano de Conta</h1>
                <Breadcrumb style={{ marginBottom: '12px' }}>
                    <Breadcrumb.Item>
                        <Icon type='home' />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to='/plano-conta'>Plano de Conta</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{planoConta? 'Editar' : 'Adicionar'}</Breadcrumb.Item>
                </Breadcrumb>

                <Card className='custom-card'>

                    <Form onSubmit={this.handleSubmit}>
                        <Row>
                            <Col>
                                <FormItem label='Conta COSIF'>
                                {
                                    getFieldDecorator('codigo_conta_cosif', {
                                        rules: [{ required: true, message: 'Conta COSIF inválida' }], 
                                        initialValue: planoConta && planoConta.codigo_conta_cosif ? planoConta.codigo_conta_cosif : '',
                                    })(
                                        <Select showSearch
                                            placeholder="Selecione uma conta COSIF"
                                            optionFilterProp="children"
                                            // filterOption={(input, option) => true}
                                            onChange={ value => {
                                                console.debug("SEL COSIF:", value);
                                                planoConta.codigo_conta_cosif = value;
                                                this._getCosifSuperior(planoConta);
                                            }}
                                        >
                                            {this.state.contasCosif}
                                        </Select>
                                    )
                                }
                                </FormItem>
                            </Col>

                        </Row>

                        <Row>
                            <Col>
                                <FormItem label='Conta COSIF Superior'>
                                    <Input disabled value={
                                        planoConta && planoConta.codigo_conta_cosif_superior ?
                                         planoConta.codigo_conta_cosif_superior + ' - ' +
                                             planoConta.codigo_conta_cosif_superior_desc
                                         : ''
                                        }
                                    />
                                </FormItem>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={10}>
                                <FormItem label='Código da Conta Interna'>
                                    {
                                        getFieldDecorator('codigo_conta_interna', {
                                            initialValue: planoConta && planoConta.codigo_conta_interna ? planoConta.codigo_conta_interna : '',
                                            rules: [{ required: true, message: 'Código da Conta inválido' }]
                                        })(
                                            <Input />
                                        )
                                    }
                                </FormItem>
                            </Col>

                            {/* <Col push={2} span={12}>
                                <FormItem label='Desdobramento'>
                                    <InputNumber disable
                                        formatter={value => desdobramentoFormater.format(value)}
                                        value={planoConta && planoConta.desdobramento ? planoConta.desdobramento : 0} />
                                </FormItem>
                            </Col> */}
                        </Row>

                        <Row>
                            <Col>
                                <FormItem label='Nome da Conta'>
                                {
                                    getFieldDecorator('nome_conta_interna', {
                                        initialValue: planoConta && planoConta.nome_conta_interna ? planoConta.nome_conta_interna : '',
                                        rules: [{ required: true, message: 'Nome da Conta inválido' }]
                                    })(
                                        <Input />
                                        )
                                }
                                </FormItem>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormItem label='Descrição da Conta'>
                                {
                                    getFieldDecorator('descricao_conta_interna', {
                                        initialValue: planoConta && planoConta.descricao_conta_interna ? planoConta.descricao_conta_interna : '',
                                    })( <Input />)
                                }
                                </FormItem>
                            </Col>
                        </Row>

                        <Row><Col>
                        <Collapse bordered={false}
                            activeKey={this.state.tributavel?'tributavel':null}
                            expandIcon={ () =>
                                <Switch
                                    checked={this.state.tributavel}
                                    onChange={value => this.setState({tributavel: value})}
                                />
                            }
                        >
                            <Collapse.Panel header={'Conta Tributavel'} key='tributavel' style={{'border': 0}}>

                                <Row>
                                    <Col span={10}>
                                        <FormItem label='Tributação'>
                                        {
                                            getFieldDecorator('codigo_tributacao', {
                                                initialValue: planoConta && planoConta.codigo_tributacao ? planoConta.codigo_tributacao : '',
                                            })(<Search enterButton />)
                                        }
                                        </FormItem>
                                    </Col>

                                    <Col push={2} span={12}>
                                        <FormItem label='Alíquota'>
                                        {
                                            getFieldDecorator('aliquota', {
                                                initialValue: planoConta && planoConta.aliquota !== null ? planoConta.aliquota : '',
                                            })(
                                                <InputNumber
                                                    decimalSeparator={','}
                                                    formatter={value => `${value} %`}
                                                    max={100}
                                                    min={0}
                                                    parser={value => value.replace('%', '')}
                                                    step={0.1}
                                                />
                                            )
                                        }
                                        </FormItem>
                                    </Col>
                                </Row>

                        </Collapse.Panel>
                        </Collapse>
                        </Col></Row>

                        <Row align="middle">
                            <Col>
                                <FormItem style={{"paddingTop": "1em"}}>
                                    <Button type='primary' icon='save' htmlType='submit'>Salvar</Button>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Content>
        )

    }

}

const WrappedNormalLoginForm = Form.create()(PlanoContaInput);

export default WrappedNormalLoginForm;
