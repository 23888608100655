import Http from '../utils/Http';

export default {

    reset() {
        console.debug("General.js::reset:start")
        window.localStorage.removeItem('general');
        console.debug("General.js::reset:end")
    },

    async downloadData() {
        try {
            console.debug("General.js::downloadData:start")
            const response = await Http.get("/general");

            console.debug("general.response.data:", response.data);
            window.localStorage.setItem('general', JSON.stringify(response.data));
            console.debug("General.js::downloadData:end")
            return response.data
        } catch (err) {
            console.error('Erro ao carregar informações gerais', err)
        }
    },

    getGeneral() {
        console.debug("General.js::getGeneral:start")
        if (this.general) { return this.general; }
        console.debug("General.js::getGeneral:dbg 1")

        var general = JSON.parse(window.localStorage.getItem('general'));

        console.debug("General.js::getGeneral:dbg 2")
        if (!general) {
            console.error("General.js::getGeneral: data not downloaded > ask to download", general)
            this.downloadData()
            throw new Error("Error: general data not present")
        }
        console.debug("General.js::getGeneral:dbg 3")

        this.general = general;
        window.general = general;
        console.debug("General.js::getGeneral:end")
        return this.general;
    },

}
