import React from 'react';

import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Card, Col, Form, Icon, InputNumber, Layout, Row, Select, DatePicker, message } from 'antd';

import moment from 'moment';
import 'moment/locale/pt-br';

import genericServiceMaker from '../../services/Service';
import generalService from '../../services/General';

import './styles.css';


const service = genericServiceMaker('tarifa')
const servicePlanoConta = genericServiceMaker('plano-conta')

moment.locale('pt-BR');

const Content = Layout
const FormItem = Form.Item
const Option = Select

class EditRecordWidget extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            general: null,
            uploading: false,
            tarifa: null,
            record: null,
            planoContas: [],
            tarifasBacen: [],
        }
        this.msgTime = 5;
    }

    initGeneral() {
        console.log("initGeneral:start")
        console.log("initGeneral:this", this)
        console.log("initGeneral:generalBefore", this.general)
        const general = generalService.getGeneral()
        console.log("initGeneral:generalGotten", general)
        this.general = general
        this.setState({general: general})
        // this.setState({general: generalService.getGeneral()});
        console.log("initGeneral:generalAfter", this.general)
        console.log("initGeneral:end")
    }

    initServices(service_name='tarifa') {
        this.initGeneral();
        this.service = genericServiceMaker(service_name);
        if (this.initServicesExtra) this.initServicesExtra();
    }

    componentDidMount() {
        console.debug("RecordEdit:componentDidMount:start");
        this.setState({ loading: true });
    
        // this.initGeneral();
        this.initServices();

        // this.getAllPlanoConta();
        // this.getTarifasBacen();
        this.getTarifa();


        this.setState({ loading: false });
        console.debug("RecordEdit::componentDidMount:end");
    }

    getTarifa() {
        const idTarifa = this.props.match.params.id;
        console.debug("idTarifa: ", idTarifa);

        if (idTarifa && idTarifa !== 'adicionar') {
            service.get(idTarifa)
            .then(response => {
                let tarifa = response.data;
                if (tarifa) {
                    console.debug("getTarifa: Setting:", tarifa)
                    this.setState({
                        disabledFields: true,
                        tarifa: tarifa,
                        maskedInputClass: 'ant-input ant-input-disabled'
                    });
                }
            })
            .catch (error => {
                console.error(error);
                message.error('Registro não encontrada', this.msgTime);
                setTimeout(function () { window.location.href = "/tarifa"; }, 2000);
            })
        }
    }

    getTarifasBacen = async () => {
        const general = this.general
        if (!general) {
            message.error('Erro ao pegar lista de tarifas', this.msgTime);
            throw new Error('No general data')
        }
        const list = Object.values(general.tarifas_bacen).map( (tarifa) =>
            <Option key={tarifa.codigo} value={tarifa.codigo}>{tarifa.codigo}: {tarifa.grupo} - {tarifa.descricao}</Option>
        )
        this.setState({ tarifasBacen: list })
    }

    getAllPlanoConta = () => {
        servicePlanoConta.getAll()
        .then(response => {
            const list = response.data.map((pc) =>
                <Option key={pc.id} value={pc.id}>{pc.codigo_conta_interna} - {pc.nome_conta_interna}</Option>
            )
            this.setState({ planoContas: list })
        })
        .catch(err => {
            this.setState({ planoContas: [] })
            message.error('Não foi possível obter os planos de conta.', this.msgTime);
            console.error('Não foi possível obter os planos de conta.', err)
        })
    }

    handleSubmit = (event) => {
        console.group()
        console.debug("handleSubmit:start")
        event.preventDefault();

        var data = this.props.form.getFieldsValue();
        this.setState({ loading: true });

        const idTarifa = this.props.match.params.id;
        let promise;
        if (!idTarifa)
            promise = service.save(data);
        else
            promise = service.update(idTarifa, data);
        promise.then(response => {
            console.debug("handleSubmit:callback")
            this.setState({ loading: false });
            message.success('Tarifa salva com sucesso', this.msgTime);
            setTimeout(function () { window.location.href = '/tarifa'; }, 3000);
            console.debug("handleSubmit:callback:finished")
            console.groupEnd()
        })
        .catch(error => {
            console.debug("handleSubmit:error:callback")
            this.setState({ loading: false });
            let message = 'Não foi possível salvar tarifa';
            if (error.response && error.response.data && error.response.data.body && typeof error.response.data.body === 'string') {
                message = error.response.data.body;
            }
            message.error(message, this.msgTime);
            console.debug("handleSubmit:error:finished")
            console.groupEnd()
        });
        // this.setState({ loading: false });
        console.debug("handleSubmit:end")
    }



    render() {

        const { getFieldDecorator } = this.props.form;
        const { tarifa } = this.state;

        // const formItemLayout = {
        //     labelCol: {
        //         xs: { span: 24 },
        //         sm: { span: 8 }
        //     },
        //     wrapperCol: {
        //         xs: { span: 24 },
        //         sm: { span: 16 }
        //     }
        // };

        // const desdobramentoFormater = new Intl.NumberFormat([], {"minimumIntegerDigits": 2})

        return (
            <Content className='custom-content' style={{ background: '#fff', padding: '24px' }}>
                <h1>Tarifa</h1>
                <Breadcrumb style={{ marginBottom: '12px' }}>
                    <Breadcrumb.Item>
                        <Icon type='home' />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to='/tarifa'>Tarifa</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{tarifa? 'Editar' : 'Adicionar'}</Breadcrumb.Item>
                </Breadcrumb>

                <Card className='custom-card'>
                    <Form onSubmit={this.handleSubmit}>

                        <Row>
                            {/* <Col span={8}> */}
                            <Col span={24}>
                                <FormItem label='Tarifa'>
                                {
                                    getFieldDecorator('cod_tarifa', {
                                        initialValue: tarifa && tarifa.cod_tarifa ? tarifa.cod_tarifa : '',
                                        rules: [{ required: true, message: 'Código de tarifa inválido' }]
                                    })(
                                        <Select showSearch
                                            placeholder="Selecione uma tarifa"
                                            optionFilterProp="children"
                                            // filterOption={(input, option) => true}
                                        >
                                            {this.state.tarifasBacen}
                                        </Select>
                                    )
                                }
                                </FormItem>
                            </Col>

                            {/* <Col push={2} span={14}>
                                <FormItem label='Descrição'>
                                   <Input disabled value={`Descrição da tarifa aqui`}/>
                                </FormItem>
                            </Col> */}
                        </Row>


                        <Row>

                            {/* TODO: Create a rule to check and validate to either tarifa_unitaria or tarifa_percentual to be filled, but never both (XOR) */}

                            <Col span={6}>
                                <FormItem label='Tarifa Unitária'>
                                    {
                                        getFieldDecorator('tarifa_unitaria', {
                                            initialValue: tarifa && tarifa.tarifa_unitaria ? tarifa.tarifa_unitaria : null,
                                            // rules: [{ required: true, message: 'Data de início de vigência da tarifa inválido' }]
                                        })(
                                            <InputNumber
                                                    decimalSeparator={','}
                                                    precision={2}
                                                    formatter={value => `R$ ${value}`}
                                                    min={0}
                                                    parser={value => value.replace(/R\$\s*/g, '')}
                                                    step={1}
                                            />
                                        )
                                    }
                                </FormItem>
                            </Col>

                            <Col pull={2} span={6}>
                                <FormItem label='Tarifa %'>
                                    {
                                        getFieldDecorator('tarifa_percentual', {
                                            initialValue: tarifa && tarifa.tarifa_percentual ? tarifa.tarifa_percentual : null,
                                            // rules: [{ required: true, message: 'Data de início de vigência da tarifa inválido' }]
                                        })(
                                            <InputNumber
                                                    decimalSeparator={','}
                                                    formatter={value => `${value} %`}
                                                    max={100}
                                                    min={0}
                                                    parser={value => value.replace('%', '')}
                                                    step={0.1}
                                            />
                                        )
                                    }
                                </FormItem>
                            </Col>

                            <Col pull={2} span={8}>
                                <FormItem label='Vigência Inicial'>
                                    {
                                        getFieldDecorator('vigencia_inicial', {
                                            initialValue: tarifa && tarifa.vigencia_inicial ?
                                                            moment(tarifa.vigencia_inicial) : null,
                                            rules: [{ required: true, message: 'Data de início de vigência da tarifa inválido' }]
                                        })(
                                            <DatePicker mode='date' />
                                        )
                                    }
                                </FormItem>
                            </Col>

                        </Row>






                        <Row>
                            <Col span={10}>
                                <FormItem label='Código da Conta Interna'>
                                    {
                                        getFieldDecorator('id_plano_conta', {
                                            initialValue: tarifa && tarifa.id_plano_conta ? tarifa.id_plano_conta : '',
                                            rules: [{ required: true, message: 'Código da Conta inválido' }]
                                        })(
                                            <Select
                                                showSearch
                                                placeholder="Selecione uma conta interna"
                                                optionFilterProp="children"
                                                // filterOption={(input, option) => true}
                                            >
                                                {this.state.planoContas}
                                            </Select>
                                        )
                                    }
                                </FormItem>
                            </Col>

                            {/* TODO: Implement this */}
                            {/* <Col push={2} span={12}>
                                <FormItem label='Desdobramento'>
                                    <InputNumber disable
                                        formatter={value => desdobramentoFormater.format(value)}
                                        value={tarifa && tarifa.desdobramento ? tarifa.desdobramento : 0} />
                                </FormItem>
                            </Col> */}
                        </Row>

                        {/* TODO: Implement this */}
                        {/* <Row>
                            <Col>
                                <FormItem label='Nome da Conta'>
                                {/ * {
                                    getFieldDecorator('nome_conta_interna', {
                                        initialValue: tarifa && tarifa.nome_conta_interna ? tarifa.nome_conta_interna : '',
                                        // rules: [{ required: true, message: 'Nome da Conta inválido' }]
                                    })(
                                        <Input disabled/>
                                        )
                                } * /}
                                </FormItem>
                            </Col>
                            </Row>

                            <Row>
                            <Col>
                                <FormItem label='Descrição da Conta'>
                                {/ * {
                                    getFieldDecorator('descricao_conta_interna', {
                                        initialValue: tarifa && tarifa.descricao_conta_interna ? tarifa.descricao_conta_interna : '',
                                    })( <Input disabled/>)
                                } * /}
                                </FormItem>
                            </Col>
                        </Row> */}


                        <Row align="middle">
                            <Col>
                                <FormItem style={{"paddingTop": "1em"}}>
                                    <Button type='primary' icon='save' htmlType='submit'>Salvar</Button>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Content>
        )

    }

}


const WrappedWidgetForm = Form.create()(EditRecordWidget);

export default WrappedWidgetForm;
