import './styles.css'
import Http from '../../utils/Http'
import { Breadcrumb, Button, Card, Col, Form, Icon, Layout, Row, Upload, message } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const Content = Layout
const FormItem = Form.Item

class PlanoContaImport extends React.Component {

    state = {
        fileList: [],
        uploading: false
    }


    handleUpload = () => {
        const { fileList, codigoContaInterna, nomeContaInterna, descricaoContaInterna, codigoContaCosif, codigoContaCosifSuperior, codigoTributacao, aliquota } = this.state
        const formData = new FormData()
        fileList.forEach((file) => {
            formData.append('files[]', file)
        });
        this.setState({
            uploading: true,
        });
        let data = {
            'aliquota': aliquota,
            'codigoContaCosif': codigoContaCosif,
            'codigoContaCosifSuperior': codigoContaCosifSuperior,
            'codigoContaInterna': codigoContaInterna,
            'codigoTributacao': codigoTributacao,
            'descricaoContaInterna': descricaoContaInterna,
            'nomeContaInterna': nomeContaInterna
        }
        Http.postFile('/plano-conta/import', 'csv', fileList[0], data).then(response => {
            this.setState({
                fileList: [],
                uploading: false,
            })
            message.success('Arquivo importado com sucesso.')
            setTimeout(function () { window.location.href = '/plano-conta'; }, 1000)
          })
          .catch(err => {
                this.setState({
                    fileList: [],
                    uploading: false,
                });
            message.error('Não foi possível realizar o upload.')
        })
    }


    validate = () => {
        const { fileList, codigoContaInterna, nomeContaInterna, descricaoContaInterna, codigoContaCosif, aliquota } = this.state;
        if(fileList.length === 0){
            return true
        }
        if(codigoContaInterna === '') {
            return true
        }
        if(nomeContaInterna === '') {
            return true
        }
        if(descricaoContaInterna === '') {
            return true
        }
        if(codigoContaCosif === '') {
            return true
        }
        if(aliquota === '') {
            return true
        }
        return false
    }


    render() {

        const { fileList, uploading } = this.state

        const props = {
            onRemove: (file) => {
                this.setState((state) => {
                    const index = state.fileList.indexOf(file)
                    const newFileList = state.fileList.slice()
                    newFileList.splice(index, 1)
                    return {
                        fileList: newFileList,
                    }
                })
            },
            beforeUpload: (file) => {
                this.setState(state => ({
                    fileList: [file],
                }))
                return false
            },
            fileList
        };

        return (
            <Content className='custom-content' style={{ background: '#fff', padding: '24px' }}>
                <h1>Plano de Conta</h1>

                <Breadcrumb style={{ marginBottom: '12px' }}>
                    <Breadcrumb.Item>
                        <Icon type='home' />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to='/plano-conta'>Plano de Conta</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Importar</Breadcrumb.Item>
                </Breadcrumb>

                <Card className='custom-card'>
                    <Form>

                        <Row>
                            <Col span={6}>
                                <FormItem label='Arquivo'>
                                    <Upload {...props}>
                                        <Button>
                                            <Icon type="upload" /> Selecionar Plano de Conta
                                        </Button>
                                    </Upload>
                                </FormItem>
                            </Col>
                            <Col span={6}>
                                <FormItem >
                                    <Button type="primary" icon='upload'
                                        onClick={this.handleUpload}
                                        disabled={this.validate()}
                                        loading={uploading}
                                        style={{ marginTop: 41 }}>
                                        {uploading ? 'Enviando' : 'Enviar'}
                                    </Button>
                                </FormItem>
                            </Col>
                        </Row>

                    </Form>
                </Card>
            </Content>
        )

    }

}

export default PlanoContaImport
