import './styles.css';
import generalService from '../../services/General';
import planoContaService from '../../services/PlanoConta';
import { Breadcrumb, Button, Card, Icon, Layout, Spin, Table, Switch, message, notification } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const Column = Table;
const Content = Layout

class PlanoContaView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            showNonTaxables: true,
            originalRecords: [],
            records: []
        }
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.general = generalService.getGeneral();
        this.buscarPlanoConta();
    }

    buscarPlanoConta = () => {
        this.setState({ loading: true });
        planoContaService.findAll().then(response => {
            let records = response.data
            this.setState({ loading: false, originalRecords: records});
            this.arrangeRecords()
          })
          .catch(err => {
                this.setState({ loading: false, records: [] });
                message.error('Não foi possível obter o plano de conta.');
                console.error('Não foi possível obter o plano de conta.', err);
          })
    }

    arrangeRecords = () => {
        let records = this.state.originalRecords

        // Don't show the non taxable ones, just taxables
        if (!this.state.showNonTaxables)
            records = records.filter( pc => pc.codigo_tributacao )

        records.forEach( pc => this._getCosifSuperior(pc) );

        // records.forEach( pc => pc.sort_data =
        //     pc.codigo_conta_interna
        //     .split('-')[0]
        //     .split('.')
        //     .map(Number)
        // );

        // records.sort( e => console.log("SORT:", e))
        // records.sort( e => e.codigo_conta_interna.split('-')[0].split('.').map(Number))
        // records = records.sort( e => e.sort_data)

        this.setState({records})
    }

    // TODO: deduplicate with input version
    _getCosifSuperior = (pc) => {
        const general = this.general
        const cosif = pc.codigo_conta_cosif
        const superior = general.conta_cosif[cosif].superior
        pc.codigo_conta_cosif_superior = superior
    }

    removerPlanoConta = (id) => {
        this.setState({ loading: true });
        planoContaService.delete(id).then(response => {
            notification.success({
                message: 'Conta removida com sucesso',
                placement: 'bottomRight'
            });
            this.buscarPlanoConta();
        })
        .catch(err => {
            this.setState({ loading: false });
            notification.error({
                message: 'Não foi possível remover conta',
                placement: 'bottomRight'
            });
        })
    }

    renderAction = (id) => {
        return [
            <Link to={`/plano-conta/${id}`}>
                <Button type="dashed" icon="edit" style={{marginRight: 5}} />
            </Link>,
            <Button onClick={() => {this.removerPlanoConta(id)}} type="dashed" icon="delete" />
            ]
    }

    render() {

        const { records } = this.state

        const desdobramentoFormater = new Intl.NumberFormat(['pt-BR'], {"minimumFractionDigits": 2})

        return (
            <Content className='custom-content' style={{ background: '#fff', padding: '24px' }}>
                <h1>Plano de Conta</h1>
                <Breadcrumb style={{ marginBottom: '12px' }}>
                    <Breadcrumb.Item>
                        <Icon type='home' />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Plano de Conta</Breadcrumb.Item>
                </Breadcrumb>

                <Spin spinning={this.state.loading} tip='Aguarde'>
                    <Card className='custom-card'>

                        <Link to='/plano-conta/adicionar'>
                            <Button type='primary' icon='plus' htmlType='submit' style={{ marginBottom: '12px', marginRight: '12px' }}>Adicionar</Button>
                        </Link>
                        <Link to='/plano-conta/importacao'>
                            <Button type='primary' icon='upload' htmlType='submit' style={{ marginBottom: '12px' }}>Importar</Button>
                        </Link>

                        <div className="showNonTaxables" style={{float: "right"}}>
                            Contas Não Tributáveis
                            <Switch
                                defaultChecked={this.state.showNonTaxables}
                                onChange={value => this.setState({showNonTaxables: value}, this.arrangeRecords)}
                                style={{marginLeft: "1em"}}
                            />
                        </div>

                        <Table dataSource={records} rowKey='id'>
                            <Column title='Nome' dataIndex='nome_conta_interna' key='nome_conta_interna' />
                            <Column title='Código' dataIndex='codigo_conta_interna' key='codigo_conta_interna' />
                            <Column title='Codigo Tributação' dataIndex='codigo_tributacao' key='codigo_tributacao'/>
                            <Column title='Alíquota' dataIndex='aliquota' key='aliquota' render={
                                aliquota => desdobramentoFormater.format(aliquota) + ' %'
                                }/>
                            <Column title='Conta COSIF' dataIndex='codigo_conta_cosif' key='codigo_conta_cosif'/>
                            {/* <Column title='Conta COSIF Superior' dataIndex='codigo_conta_cosif_superior' key='codigo_conta_cosif_superior' /> */}
                            <Column title='' dataIndex='id'
                                render={
                                    (id) => (
                                        this.renderAction(id)
                                    )
                                }
                            />
                        </Table>

                    </Card>
                </Spin>
            </Content>
        )

    }

}

export default PlanoContaView
