import './styles.css';
import empresaService from '../../services/Empresa';
import Http from '../../utils/Http';
import { Breadcrumb, Button, Card, Icon, Layout, Spin, Table, message, notification } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const Column = Table;
const Content = Layout

class EmpresaView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            listaEmpresa: []
        };
    }

    componentDidMount() {
        const perfil = window.localStorage.getItem('clientProfile')
        if (perfil === 'Master') {
            this.getEmpresa();
        } else {
            this.getEmpresaByUsuario();
        }
    }

    getEmpresa() {
        this.setState({ loading: true });
        empresaService.findAll()
        .then(response => {
            const lista = response.data;
            this.setState({ listaEmpresa: lista, loading: false });
        })
        .catch(error => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    getEmpresaByUsuario() {
        this.setState({ loading: true });
        empresaService.findByUser()
        .then(response => {
            const lista = response.data;
            this.setState({ listaEmpresa: lista, loading: false });
        })
        .catch(error => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    buscarEmpresaUsuario = () => {
        Http.get("/empresa/usuario").then(response => {
            this.setState({
              resultado: response.data
            })
          })
          .catch(err => {
                this.setState({
                    resultado: []
                });
                message.error('Não foi possível obter a(s) empresa(s).')
                console.error('Não foi possível obter a(s) empresa(s).', err)
          })
    }

    removerEmpresa = (id) => {
        this.setState({ loading: true });
        empresaService.delete(id)
        .then(response => {
            this.setState({ loading: false });
            notification.success({
                message: 'Empresa removida com sucesso',
                placement: 'bottomRight'
            });
            setTimeout(function () { window.location.href = '/empresa'; }, 1000);
        })
        .catch(err => {
            this.setState({ loading: false });
            notification.error({
                message: 'Não foi possível remover empresa',
                placement: 'bottomRight'
            });
        })
    }

    renderAction = (id) => {
        return [
            <Link to={`/empresa/${id}`}>
                <Button type="dashed" icon="edit" style={{marginRight: 5}} />
            </Link>,
            <Button onClick={() => {this.removerEmpresa(id)}} type="dashed" icon="delete" />
        ]
    }

    render() {

        return (
            <Content className='custom-content' style={{ background: '#fff', padding: '24px' }}>
                <h1>Empresa</h1>
                <Breadcrumb style={{ marginBottom: '12px' }}>
                    <Breadcrumb.Item>
                        <Icon type='home' />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Empresa</Breadcrumb.Item>
                </Breadcrumb>
                <Spin spinning={this.state.loading} tip='Aguarde'>
                    <Card className='custom-card'>
                        <Link to='/empresa/adicionar'>
                            <Button type='primary' icon='plus' htmlType='submit' style={{ marginBottom: '12px' }}>Adicionar</Button>
                        </Link>
                        <Table dataSource={this.state.listaEmpresa}>
                            <Column title='CNPJ' dataIndex='ni' key='ni' />
                            <Column title='Nome' dataIndex='nome_empresarial' key='nome_empresarial' />
                            <Column title='Tipo da Empresa' dataIndex='nome_tipo_empresa' key='nome_tipo_empresa' />
                            <Column title='' dataIndex='id' key='id'
                                render={
                                    (id) => (
                                        this.renderAction(id)
                                    )
                                }
                            />
                        </Table>
                    </Card>
                </Spin>
            </Content>
        )

    }

}

export default EmpresaView
