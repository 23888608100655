import Http from '../utils/Http';

export default {

    findAll() {
        return Http.get('/municipio');
    },

    findByCodigoUnidadeFederativa(codigo) {
        return Http.get('/municipio/unidade-federativa/' + codigo);
    }

}
