import './styles.css'
// import { Menu } from 'antd'
import React from 'react'

class Header extends React.Component {

    render() {

        // const menu = (
        //     <Menu>
        //         <Menu.Item key='1' disabled>Eric Franco</Menu.Item>
        //         <Menu.Item key='2'>Sair</Menu.Item>
        //     </Menu>
        // )

        return (
            <div className='header'>
                <div>
                    <img alt='iDesif' className='logo' src={require('../../assets/logo.png')} />
                </div>
            </div>
        )

    }

}

export default Header
