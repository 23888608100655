import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Card, Icon, Layout, Spin, Table, message, notification } from 'antd';
import genericServiceMaker from '../../services/Service';
import './styles.css';

const service = genericServiceMaker('tarifa')

const Column = Table;
const Content = Layout

class TarifaView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            resultado: []
        }
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.buscarTarifa();
        this.setState({ loading: false });
    }


    buscarTarifa = () => {
        this.setState({ loading: true });
        service.getAll().then(response => {
            const un_formater = new Intl.NumberFormat('pt-BR', {style: 'currency', currency:'BRL'})
            const per_formater = new Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2})
            response.data.forEach( tarifa => {
                tarifa.valor = tarifa.tarifa_unitaria?
                    un_formater.format(tarifa.tarifa_unitaria):
                    per_formater.format(tarifa.tarifa_percentual) + ' %'
            })
            this.setState({ loading: false, resultado: response.data });
          })
          .catch(err => {
                this.setState({ loading: false, resultado: [] });
                message.error('Não foi possível obter as tarifas.');
                console.error('Não foi possível obter as tarifas.', err);
          })
    }


    removerTarifa = (id) => {
        this.setState({ loading: true });
        service.delete(id).then(response => {
            this.buscarTarifa();
            notification.success({
                message: 'Tarifa removida com sucesso',
                placement: 'bottomRight'
            });
        })
        .catch(err => {
            this.buscarTarifa();
            notification.error({
                message: 'Não foi possível remover tarifa',
                placement: 'bottomRight'
            });
            this.setState({ loading: false });
        })
    }

    renderAction = (id) => {
        return (
        <div>
            <Link to={`/tarifa/${id}`}>
                <Button type="dashed" icon="edit" style={{marginRight: 5}} />
            </Link>
            <Button onClick={() => {this.removerTarifa(id)}} type="dashed" icon="delete" />
        </div>)
    }


    render() {

        const { resultado } = this.state

        return (
            <Content className='custom-content' style={{ background: '#fff', padding: '24px' }}>
                <h1>Tarifa</h1>
                <Breadcrumb style={{ marginBottom: '12px' }}>
                    <Breadcrumb.Item>
                        <Icon type='home' />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Tarifa</Breadcrumb.Item>
                </Breadcrumb>

                <Spin spinning={this.state.loading} tip='Aguarde'>
                    <Card className='custom-card'>
                        <Link to='/tarifa/adicionar'>
                            <Button type='primary' icon='plus' htmlType='submit' style={{ marginBottom: '12px', marginRight: '12px' }}>Adicionar</Button>
                        </Link>
                        {/* <Link to='/tarifa/importacao'>
                            <Button type='primary' icon='upload' htmlType='submit' style={{ marginBottom: '12px' }}>Importar</Button>
                        </Link> */}

                        <Table dataSource={resultado} rowKey='id'>
                            <Column title='Código' dataIndex='cod_tarifa' />
                            <Column title='Conta Interna' dataIndex='conta_interna.codigo_conta_interna' key='conta_interna' />
                            {/* <Column title='Tarifa Unitária' dataIndex='tarifa_unitaria' key='tarifa_unitaria' /> */}
                            {/* <Column title='Tarifa Percentual' dataIndex='tarifa_percentual' key='tarifa_percentual' /> */}
                            <Column title='Tarifa' align='right' dataIndex='valor' key='tarifa' />
                            <Column title='Vigência Inicial' dataIndex='vigencia_inicial' key='vigencia_inicial' />
                            <Column render={record => this.renderAction(record.id)} />
                        </Table>

                    </Card>
                </Spin>
            </Content>
        )

    }

}

export default TarifaView

