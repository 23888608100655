/* eslint-disable no-unused-vars */
import React from 'react'
import { Link } from 'react-router-dom'
import fileDownload from 'js-file-download'
import { Breadcrumb, Button, Card, Col, Form, Table, Icon, Tag, Input, Layout, Row, Select, DatePicker, Divider, Modal, Popconfirm, message} from 'antd'
import moment from 'moment';
import 'moment/locale/pt-br';
import generalServiceGetter from '../../services/General';
import genericServiceMaker from '../../services/Service';
import EmpresaSelector from '../Widgets/EmpresaSelector';
import './styles.css'

moment.locale('pt-BR');
const monthFormat = 'MM / YYYY';
const dayFormat = 'DD/MM/YYYY';
const timeStampFormat = dayFormat + ' HH:MM:SS';

const Column = Table
const Content = Layout
const FormItem = Form.Item
const Option = Select
const Search = Input.Search
const { MonthPicker } = DatePicker;


const service = genericServiceMaker('demonstrativo')

// console.dir(general)
// console.dir(service)

const msgTime = 5


class DemonstrativoContabilInput extends React.Component {

    state = {
        records: [],

        visibleModalProtocolo: false,
        visibleModalRetificacao: false,
        currentRecordId: null,
        currentProtocolo: "",
        currentRetificacao: "",
    }

    componentDidMount(){
        this.general = generalServiceGetter.getGeneral()
        this.getAll();
    }

    getAll = async (cancelados=false) => {
        try {
            const response = await service.getAll()
            let records = response.data
            if(!cancelados)
                records = records.filter(e => e.status!=="Cancelado")
            this.setState({records});
        } catch(err) {
            this.setState({ records: [] });
            console.error('Não foi possível obter os registros.', err)
            message.error('Não foi possível obter os registros.', msgTime);
        }
    }


    handleSubmit = (event) => {
        console.group()
        console.debug("handleSubmit:start")
        event.preventDefault();

        var data = this.props.form.getFieldsValue();
        data.mes_fim = data.mes_fim.format('YYYYMM')
        data.mes_inicio = data.mes_inicio.format('YYYYMM')
        data.id_empresas = this.id_empresas; // FIXME: Workaround while fix empresa selector
        console.log("SUBMIT DATA:", data)
        console.log("SUBMIT THIS:", this)

        this.setState({ loading: true });
        service.save(data).then(response => {
            console.debug("handleSubmit:callback")
            this.setState({ loading: false });
            message.success('Registro salvo com sucesso', msgTime);
            this.getAll()
            console.debug("handleSubmit:callback:finished")
            console.groupEnd()
        })
        .catch(error => {
            console.debug("handleSubmit:error:callback")
            this.setState({ loading: false })
            const msg = error.response.data.error || 'Não foi possível salvar o registro'
            message.error(msg, msgTime)
            console.error(msg)
            console.debug("handleSubmit:error:finished")
            console.groupEnd()
        });

        // this.setState({ loading: false });
        console.debug("handleSubmit:end")
    }

    cancelRecord = (id) => {
        console.log('CANCELING:', id)
        service.cancel(id).then(response => {
            message.success('Registro cancelado com sucesso.', msgTime);
            this.getAll()
          })
          .catch(err => {
                message.error('Não foi possível cancelar o registro.', msgTime);
                console.error('Não foi possível cancelar o registro.', err)
          })
    }


    sendProtocol = () => {
        const id = this.state.currentRecordId
        let param = { "protocolo": this.state.currentProtocolo }
        service.setProtocol(id, param).then(response => {
            message.success('Protocolo salvo com com sucesso.', msgTime);
            this.hideModal()
            this.getAll()
        })
        .catch(err => {
            message.error('Não foi possível alterar o protocolo.', msgTime);
            console.error('Não foi possível alterar o protocolo.', err)
          })
    }


    // TODO: WIP
    sentRectification = () => {
        const id = this.state.currentRecordId
        let param = { "codigo_retificacao": this.state.currentRetificacao }
        service.setRectification(id, param).then(response => {
            message.success('Código da retificação atualizado com com sucesso.', msgTime);
            this.hideModal()
            this.getAll()
          })
          .catch(err => {
              message.error('Não foi possível alterar o código da retificação.', msgTime);
              console.error('Não foi possível alterar o código da retificação.', err)
          })
    }


    // TODO: WIP
    downloadRecord = (row) => {
        service.download(row.id).then(response => {
            message.success('Arquivo gerado com sucesso.', msgTime);
            const cnpj = String(row.empresa.ni).replace('/','').replace('-','').replace(/\./g, '')
            let nomeArquivo = `DEMONSTRATIVO_CONTABIL ${row.mes_inicio}-${row.mes_fim}.txt`
            fileDownload(response.data, nomeArquivo, 'application/octet-stream')
          })
          .catch(err => {
                message.error('Não foi possível gerar os registros.', msgTime);
                console.error('Não foi possível gerar os registros.', err);
          })
    }



    // TODO: WIP
    showModalRetificacao = (record) => {
        this.setState({
            visibleModalRetificacao: true,
            // currentRetificacao: record.codigo_retificacao,
            currentRecordId: record.id
        });
    }

    showModalProcotolo = (record) => {
        this.setState({
            visibleModalProtocolo: true,
            // currentProtocolo: record.protocolo,
            currentRecordId: record.id
        });
    }

    hideModal = () => {
        this.setState({
            visibleModalProtocolo: false,
            visibleModalRetificacao: false,
            currentProtocolo: "",
            currentRetificacao: "",
            currentRecordId: null
        });
    }

    renderAction = (record) => {
        const {status, id} = record
        if (status !== "Cancelado")
            return (
            <div>
                {/* <Button onClick={() => {this.showModalProcotolo(id)}} type="dashed" title="Adicionar protocolo" alt="Adicionar protocolo" icon="file-ppt" style={{marginRight: 5}} /> */}
                {/* <Button onClick={() => {this.showModalCancelar(id)}} type="dashed" title="Cancelar" icon="close" style={{marginRight: 5}} /> */}
                {/* <Button onClick={() => {this.downloadRecord(id)}} type="dashed" title="Download" icon="download" /> */}
                {/* <Button onClick={() => {this.showModalRetificacao(id)}} type="dashed" title="Adicionar retificação" alt="Adicionar retificação" icon="snippets" style={{marginRight: 5}} /> */}

                <Button onClick={() => {this.downloadRecord(record)}} type="dashed" title="Download" icon="download" />

                <Button onClick={() => {this.showModalProcotolo(record)}} disabled={status!=="Importado"} type="dashed" title="Adicionar protocolo" alt="Adicionar protocolo" icon="file-ppt" style={{marginRight: 5}} />

                <Button onClick={() => {this.showModalRetificacao(record)}} disabled={status!=="?????"} type="dashed" title="Adicionar retificação" alt="Adicionar retificação" icon="snippets" style={{marginRight: 5}} />

                { status==="Protocolado" ? (
                    <Button disabled type="dashed" title="Cancelar" icon="close" style={{marginRight: 5}} />
                    ) : (
                    <Popconfirm
                        title='Deseja realmente cancelar?'
                        okText='Sim' cancelText='Não'
                        onConfirm={() => this.cancelRecord(id)}
                    >
                        <Button type="dashed" title="Cancelar" icon="close" style={{marginRight: 5}} />
                    </Popconfirm>
                )}

            </div>
            )
    }

    render() {
        const { records, currentProtocolo, currentRetificacao } = this.state
        const { getFieldDecorator } = this.props.form;

        const monthFormat = 'MM / YYYY';

        const now = moment()
        // year_half: false on 1st half of the year, true on the 2nd half
        let year_half = now.month() >= 6
        if (!year_half) now.year(now.year()-1);
        year_half = !year_half

        let default_start = moment(now).startOf('year')
        let default_end = moment(now).endOf('year')
        if (year_half) default_start = default_start.month(6)
        if (!year_half) default_end = default_end.month(5)


        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        return (
            <Content className='custom-content' style={{ background: '#fff', padding: '24px' }}>
                <h1>Demonstrtativo Contábil</h1>
                <Breadcrumb style={{ marginBottom: '12px' }}>
                    <Breadcrumb.Item>
                        <Icon type='home' />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Demonstrtativo Contábil</Breadcrumb.Item>
                </Breadcrumb>

                {/* <Card className='custom-card' style={{paddingBottom: 0}}> */}
                <Card className='custom-card'>
                    <Form onSubmit={this.handleSubmit}>
                        <Row type='flex' align='bottom'>
                            <Col offset={0} span={10}>
                            { getFieldDecorator('id_empresa', {
                                    // FIXME: work EmpresaSector with forms
                                    valuePropName: 'getIds',
                                    rules: [{ required: true, message: 'Selecione ao menos uma empresa' }]
                                })(
                                <FormItem
                                labelCol={{span: 4}}
                                wrapperCol={{span: 20}}
                                label='Empresa'>
                                    <EmpresaSelector onChange={value => this.id_empresas = value} />
                                </FormItem>
                            )}
                            </Col>
                            <Col span={12}>
                                <FormItem
                                labelCol={{span: 12}}
                                wrapperCol={{span: 12}}
                                label='Protocolo (Caso Retificação)' >
                                    { getFieldDecorator('protocolo')( <Input /> ) }
                                </FormItem>
                            </Col>
                            {/* <Col push={4} span={2} >
                                <FormItem>
                                    <Button type='primary' icon='file' htmlType='submit'>Criar</Button>
                                </FormItem>
                            </Col> */}
                        </Row>

                        <Row>
                            <Col span={8}>
                                <FormItem {...formItemLayout} label='Mês do Inicio'>
                                {
                                    getFieldDecorator('mes_inicio', {
                                                initialValue: default_start,
                                                rules: [{ required: true, message: 'Data de início inválido' }]
                                            })(
                                    <MonthPicker
                                    // <DatePicker
                                        // mode='year'
                                        placeholder='MM / AAAA'
                                        allowClear={false}
                                        format={monthFormat}
                                        />)
                                }
                                </FormItem>
                            </Col>

                            <Col offset={1} span={8}>
                                <FormItem {...formItemLayout} label='Mês do Fim'>
                                {
                                    getFieldDecorator('mes_fim', {
                                                initialValue: default_end,
                                                rules: [{ required: true, message: 'Data de fim inválido' }]
                                            })(
                                    <MonthPicker
                                        placeholder='MM / AAAA'
                                        allowClear={false}
                                        format={monthFormat}
                                        />)
                                }
                                </FormItem>
                            </Col>
                            <Col offset={3} span={2}>
                                <FormItem>
                                    <Button type='primary' icon='plus' htmlType='submit'>Criar</Button>
                                </FormItem>
                            </Col>
                        </Row>

                    </Form>
                </Card>

                {/* <Divider style={{marginTop: 0}} /> */}

                <Card>
                    <Table dataSource={records} rowKey='id'>
                        <Column title='CNPJ' dataIndex='empresa.ni'
                            render={cnpj => cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')}
                        />
                        <Column title='Inicio' dataIndex='mes_inicio' key='mes_inicio'
                            render={ r => moment(r, 'YYYYDD').format(monthFormat)} />
                        <Column title='Fim' dataIndex='mes_fim' key='mes_fim'
                            render={ r => moment(r, 'YYYYDD').format(monthFormat)} />
                        <Column title='Data de Processamento' dataIndex='data_criacao' key='data_criacao'
                            render={ r => moment(r).format(timeStampFormat)} />
                        <Column title='Protocolo' dataIndex='protocolo' key='protocolo' />
                        <Column title='Status' dataIndex='status' key='status'
                            render={status => <Tag color='green'>{status}</Tag>} />
                        <Column
                            defaultSortOrder='descend'
                            sorter={(a, b) => a.id - b.id}
                            render={record => this.renderAction(record)}
                        />
                    </Table>
                </Card>

                <Modal title="Protocolo"
                    visible={this.state.visibleModalProtocolo}
                    onOk={this.sendProtocol}
                    onCancel={this.hideModal}
                    okText="Protocolar"
                    cancelText="Cancelar"
                    >
                    <Input placeholder="Protocolo"
                        value={currentProtocolo}
                        onChange={evt => this.setState({currentProtocolo: evt.target.value})}
                    />
                </Modal>

                <Modal title="Código Retificação"
                    visible={this.state.visibleModalRetificacao}
                    onOk={this.sentRectification}
                    onCancel={this.hideModal}
                    okText="Retificar"
                    cancelText="Cancelar"
                    >
                    <Input placeholder="Retificacao"
                        value={currentRetificacao}
                        onChange={evt => this.setState({currentRetificacao: evt.target.value})}
                    />
                </Modal>

            </Content>
        )

    }

}


const WrappedDemonstrativoForm = Form.create()(DemonstrativoContabilInput);

export default WrappedDemonstrativoForm
