import './styles.css'
import { Breadcrumb, Button, message, Card, Col, Form, Icon, Layout, Row, Select, Upload, DatePicker } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment';
import 'moment/locale/pt-br';
import Http from '../../utils/Http'
const { MonthPicker } = DatePicker;

const Content = Layout
const FormItem = Form.Item
const Option = Select
moment.locale('pt-BR');

class BalanceteInput extends React.Component {
    state = {
        fileList: [],
        uploading: false,
        idEmpresa: "",
        mesReferencia: "01/2019",
        listaEmpresas: []
      }

      componentDidMount(){
        this.buscarEmpresas();
    }
      buscarEmpresas = () => {
        Http.get("/empresa/usuario").then(response => {
            const lista = response.data.map((empresa) => <Option key={empresa.id} value={empresa.id}>{empresa.nome_fantasia} - {empresa.ni}</Option>)
            this.setState({ listaEmpresas: lista })
          })
          .catch(err => {
                this.setState({
                    empresas: []
                });
                message.error('Não foi possível obter as empresas.');
                console.error('Não foi possível obter as empresas.', err)
          })
    }
      selecionarMesReferencia = (value) => {
          if(value){
            let data_final = '' + String(value._d.getUTCMonth() + 1).padStart(2, '0') + '/' + value._d.getUTCFullYear();
            this.setState({mesReferencia: data_final});
          }
      }
      selecionarEmpresa = (value) => {
        this.setState({idEmpresa: value});
      }
      validarEnvio = () => {
        const { fileList, idEmpresa, mesReferencia } = this.state;
        if(fileList.length === 0){
            return true
        }
        if(idEmpresa === "") {
            return true
        }
        if(mesReferencia === "") {
            return true
        }
        return false
        //   return this.state.fileList.length === 0
      }
      handleUpload = () => {
        const { fileList, idEmpresa, mesReferencia } = this.state;
        const formData = new FormData();
        fileList.forEach((file) => {
          formData.append('files[]', file);
        });

        this.setState({
          uploading: true,
        });
        let data = {
            "idEmpresa": idEmpresa,
            "mesReferencia": mesReferencia
        }
        Http.postFile("/balancete", 'csv', fileList[0], data).then(response => {
            this.setState({
              fileList: [],
              uploading: false,
            });
            message.success('Arquivo importado com sucesso.');
            setTimeout(function () { window.location.href = "/balancete"; }, 3000);
          })
          .catch(err => {
                this.setState({
                  fileList: [],
                  uploading: false,
                });
                message.error('Não foi possível realizar o upload. ' +  String(err.response.data.error));
          })

      }
    render() {
        const { uploading, fileList } = this.state;

        const monthFormat = 'MM/YYYY';
        // const formItemLayout = {
        //     labelCol: {
        //         xs: { span: 24 },
        //         sm: { span: 8 },
        //     },
        //     wrapperCol: {
        //         xs: { span: 24 },
        //         sm: { span: 16 },
        //     },
        // };
        const props = {
            onRemove: (file) => {
              this.setState((state) => {
                const index = state.fileList.indexOf(file);
                const newFileList = state.fileList.slice();
                newFileList.splice(index, 1);
                return {
                  fileList: newFileList,
                };
              });
            },
            beforeUpload: (file) => {
              this.setState(state => ({
                // fileList: [...state.fileList, file],
                fileList: [file],
              }));
              return false;
            },
            fileList,
          };
        return (
            <Content className='custom-content' style={{ background: '#fff', padding: '24px' }}>
                <h1>Balancete</h1>
                <Breadcrumb style={{ marginBottom: '12px' }}>
                    <Breadcrumb.Item>
                        <Icon type='home' />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to='/balancete'>Balancete</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Cadastrar</Breadcrumb.Item>
                </Breadcrumb>
                <Card className='custom-card'>
                    <Form>
                        <Row>
                            <Col span={8} >
                                <FormItem  label='Empresa'>
                                    <Select defaultValue={''} onChange={(value) => this.selecionarEmpresa(value)}>
                                        <Option value=''>Selecione</Option>
                                        {this.state.listaEmpresas}
                                        {/* <Option value='1'>Empresa 1</Option>
                                        <Option value='2'>Empresa 2</Option> */}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span={1}></Col>
                            <Col span={12}>
                                <FormItem  label='Mês/Ano de Referência'>
                                    <MonthPicker onChange={(value) => this.selecionarMesReferencia(value)}
                                        placeholder='DD/AAAA'
                                        allowClear={false}
                                        defaultValue={moment('01/2019', monthFormat)} format={monthFormat} />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={5}>
                                <FormItem label='Balancete'>
                                    <Upload {...props}>
                                        <Button>
                                            <Icon type="upload" /> Selecionar balancete
                                        </Button>
                                    </Upload>
                                </FormItem>
                            </Col>
                            <Col span={1/2}></Col>
                            <Col span={6}>
                                <FormItem >
                                    <Button type="primary" icon='upload'
                                        onClick={this.handleUpload}
                                        disabled={this.validarEnvio()}
                                        loading={uploading}
                                        style={{ marginTop: 41 }}>
                                        {uploading ? 'Enviando' : 'Enviar' }
                                    </Button>
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Content>
        )

    }

}

export default BalanceteInput
