import axios from 'axios';

const apiURL = 'https://viacep.com.br/ws/';

class HttpViaCEP {

    constructor() {
        let axiosConfiguration = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            timeout: 300000
        };
        axiosConfiguration['baseURL'] = apiURL;
        this.axios = axios.create(axiosConfiguration);
    }

    get(path, params) {
        return this.axios.get(path + '/json', { params });
    }

}

export default new HttpViaCEP();
