import './styles.css';
import usuarioService from '../../services/Usuario';
import { Breadcrumb, Button, Card, Icon, Layout, Spin, Table } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const Column = Table;
const Content = Layout

class UsuarioView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            users: []
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.getAllUser()
        console.log(this.users)
        this.setState({ loading: false });
    }

    deleteUserById = (id) => {
        usuarioService.deleteUserById(id)
        .then(response => {
            console.error(response)
        })
        .catch(error => {
            console.error(error)
        })
    }

    getAllUser = () => {
        usuarioService.findAll()
        .then(response => {
            console.log(response.data)
            const list = response.data
            this.setState({ users: list })
        })
        .catch(error => {
            console.error(error)
        })
    }

    renderAction = (id) => {
        return [
            <Link to={`/usuario/${id}`}>
                <Button disabled type="dashed" icon="lock" style={{marginRight: 5}} />
            </Link>,
            <Button disabled onClick={() => {this.deleteUserById(id)}} type="dashed" icon="delete" />
        ]
    }

    render() {

        return (
            <Content className='custom-content' style={{ background: '#fff', padding: '24px' }}>
                <h1>Usuário</h1>
                <Breadcrumb style={{ marginBottom: '12px' }}>
                    <Breadcrumb.Item>
                        <Icon type='home' />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Usuário</Breadcrumb.Item>
                </Breadcrumb>
                <Spin spinning={this.state.loading} tip='Aguarde'>
                    <Card className='custom-card'>
                        <Link to='/usuario/adicionar'>
                            <Button type='primary' icon='plus' htmlType='submit' style={{ marginBottom: '12px' }}>Adicionar</Button>
                        </Link>
                        <Table dataSource={this.state.users}>
                            <Column title='Nome' dataIndex='nome' key='nome' />
                            <Column title='Sobrenome' dataIndex='sobrenome' key='sobrenome' />
                            <Column title='E-mail' dataIndex='email' key='email' />
                            <Column title='' dataIndex='id' key='id'
                                render={
                                    (id) => (
                                        this.renderAction(id)
                                    )
                                }
                            />
                        </Table>
                    </Card>
                </Spin>
            </Content>
        )

    }

}

export default UsuarioView
