import './styles.css'
import empresaService from '../../services/Empresa'
import usuarioService from '../../services/Usuario'
import { Breadcrumb, Button, Card, Col, Form, Icon, Input, Layout, Row, Select, notification } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const Content = Layout
const FormItem = Form.Item
const Option = Select

class UsuarioInput extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            fileList: [],
            companyId: null,
            companies: [],
            uploading: false
        }
    }

    componentDidMount() {
        this.setState({ loading: true })
        this.getAllCompany()
        this.setState({ loading: false })
    }

    getAllCompany = () => {
        empresaService.findAll()
        .then(response => {
            const list = response.data.map((empresa) => <Option key={empresa.id} value={empresa.id}>{empresa.nome_fantasia} - {empresa.ni}</Option>)
            this.setState({ companies: list })
        })
        .catch(err => {
            this.setState({ companies: [] })
            notification.error('Não foi possível obter as empresas.')
            console.error('Não foi possível obter as empresas.', err)
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        var user = this.props.form.getFieldsValue();
        this.setState({ loading: true });
        console.log(user)
        usuarioService.save(user)
        .then(response => {
            this.setState({ loading: false });
            notification.success({
                message: 'Usuário adicionado com sucesso',
                placement: 'bottomRight'
            });
            setTimeout(function () { window.location.href = '/usuario'; }, 3000);
        })
        .catch(error => {
            this.setState({ loading: false });
            let message = 'Não foi possível adicionar usuário';
            if (error.response && error.response.data && error.response.data.body && typeof error.response.data.body === 'string') {
                message = error.response.data.body;
            }
            notification.error({
                message,
                placement: 'bottomRight'
            });
        });
        this.setState({ loading: false });
    }

    selectCompany(id) {
        this.setState({ companyId: id })
    }

    render() {

        const { getFieldDecorator } = this.props.form

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        return (
            <Content className='custom-content' style={{ background: '#fff', padding: '24px' }}>
                <h1>Usuário</h1>
                <Breadcrumb style={{ marginBottom: '12px' }}>
                    <Breadcrumb.Item>
                        <Icon type='home' />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to='/usuario'>Usuário</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Adicionar</Breadcrumb.Item>
                </Breadcrumb>
                <Card className='custom-card'>
                    <Form onSubmit={this.handleSubmit}>
                        <Row>
                            <Col span={12} >
                                <FormItem {...formItemLayout} label='Empresa'>
                                    {
                                        getFieldDecorator('id_empresa', {
                                            initialValue: '',
                                            rules: [{ required: true }]
                                        })(
                                            <Select>
                                                <Option value=''></Option>
                                                {this.state.companies}
                                            </Select>
                                        )
                                    }
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label='E-mail'>
                                    {
                                        getFieldDecorator('email', {
                                            rules: [{ required: true }]
                                        })(
                                            <Input />
                                        )
                                    }
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label='Senha'>
                                    {
                                        getFieldDecorator('senha', {
                                            rules: [{ required: true }]
                                        })(
                                            <Input.Password />
                                        )
                                    }
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label='Nome'>
                                    {
                                        getFieldDecorator('nome', {
                                            rules: [{ required: true }]
                                        })(
                                            <Input />
                                        )
                                    }
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label='Sobrenome'>
                                    {
                                        getFieldDecorator('sobrenome', {
                                            rules: [{ required: true }]
                                        })(
                                            <Input />
                                        )
                                    }
                                </FormItem>
                            </Col>
                        </Row>
                        <FormItem>
                            <Button type='primary' icon='save' htmlType='submit'>Salvar</Button>
                        </FormItem>
                    </Form>
                </Card>
            </Content>
        )

    }

}

const WrappedNormalLoginForm = Form.create()(UsuarioInput);

export default WrappedNormalLoginForm;
