import './styles.css'
import { Breadcrumb, Button, message, Card, Icon, Layout, Table, Modal, Tag , Input} from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import Http from '../../utils/Http'
import fileDownload from 'js-file-download'

const Column = Table;
const Content = Layout

class BalanceteView extends React.Component {
    state = {
        resultado: [],
        empresas: [],
        visibleModalProtocolo: false,
        visibleModalCancelar: false,
        currentProtocolo: "",
        currentApuracaoId: ""
    }
    componentDidMount(){
        this.buscarApuracoes();
    }

    atualizarProtocolo = (e) => {
        this.setState({currentProtocolo: e.target.value})
    }

    downloadApuracao = (row) => {
        Http.download("/apuracao/gerar/" + row.id, {}).then(response => {
            message.success('Arquivo gerado com sucesso.');
            let nomeArquivo = '' + String(row.cnpj).replace('/','').replace('-','').replace(/\./g, '') + '_' + row.mesano + '.txt'
            fileDownload(response.data, nomeArquivo, 'application/octet-stream')
            // this.hideModal()
            // setTimeout(function () { window.location.href = "/apuracao-mensal-issqn"; }, 3000);
          })
          .catch(err => {
                message.error('Não foi possível gerar o balancete.');
          })
    }

    enviarProtocolo = () => {
        let param = {
            "apuracao_id": this.state.currentApuracaoId,
            "protocolo": this.state.currentProtocolo
        }
        Http.post("/apuracao/protocolo", param).then(response => {
            message.success('Protocolo salvo com com sucesso.');
            this.hideModal()
            setTimeout(function () { window.location.href = "/balancete"; }, 3000);
          })
          .catch(err => {
                message.error('Não foi possível alterar o protocolo.');
                console.error('Não foi possível alterar o protocolo.', err)
          })
    }

    cancelarApuracao = () => {
        let param = {
            "apuracao_id": this.state.currentApuracaoId
        }
        Http.post("/apuracao/cancelar", param).then(response => {
            message.success('Balancete cancelado com sucesso.');
            this.hideModal()
            setTimeout(function () { window.location.href = "/balancete"; }, 3000);
          })
          .catch(err => {
                message.error('Não foi possível cancelar o balancete. ' + String(err.response.data.error));
                console.error('Não foi possível cancelar o balancete.', err)
                this.hideModal()
          })
    }

    showModalProcotolo = (row) => {
        this.setState({
            visibleModalProtocolo: true,
            currentProtocolo: row.protocolo,
            currentApuracaoId: row.id
        });
      }
      showModalCancelar = (row) => {
        this.setState({
            visibleModalCancelar: true,
            currentApuracaoId: row.id
        });
      }


    hideModal = () => {
        this.setState({
            visibleModalProtocolo: false,
            visibleModalCancelar: false,
            currentProtocolo: "",
            currentApuracaoId: ""
        });
      }

    renderAction = (status, row) => {
        if (row.status !== "Cancelado"){
            return <Button onClick={() => {this.showModalCancelar(row)}} type="dashed" icon="close" style={{marginRight: 5}} />
            // return [<Button onClick={() => {this.showModalProcotolo(row)}} type="dashed" alt="Adicionar protocolo" icon="file-ppt" style={{marginRight: 5}} />,
            // <Button onClick={() => {this.showModalCancelar(row)}} type="dashed" icon="close" style={{marginRight: 5}} />,
            // <Button onClick={() => {this.downloadApuracao(row)}} type="dashed" icon="download"  />  ]
        }
    }
    buscarApuracoes = () => {
        Http.get("/balancete").then(response => {
            let resultado = response.data
            resultado = resultado.filter(e =>
                    !["Cancelado", "Importado"].includes(e.status) )
            this.setState({ resultado });
          })
          .catch(err => {
                this.setState({
                    resultado: []
                });
                message.error('Não foi possível obter o balancete.');
                console.error('Não foi possível obter o balancete.', err)
          })
    }


    render() {
        const {resultado, currentProtocolo} = this.state

        return (
            <Content className='custom-content' style={{ background: '#fff', padding: '24px' }}>
                <h1>Balancete</h1>
                <Breadcrumb style={{ marginBottom: '12px' }}>
                    <Breadcrumb.Item>
                        <Icon type='home' />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Balancete</Breadcrumb.Item>
                </Breadcrumb>
                <Card className='custom-card'>
                    <Link to='/balancete/cadastrar'>
                        <Button type='primary' icon='plus' htmlType='submit' style={{ marginBottom: '12px' }}>Cadastrar</Button>
                    </Link>
                    <Table dataSource={resultado}>
                        <Column title='Mês/Ano' dataIndex='mesano' key='mesano' />
                        <Column title='Data de Processamento' dataIndex='dataprocessamento' key='dataprocessamento' />
                        <Column title='Empresa' dataIndex='empresa' key='empresa' />
                        {/* <Column title='Cidade' dataIndex='cidade' key='cidade' /> */}
                        <Column title='CNPJ' dataIndex='cnpj' key='cnpj' />
                        {/* <Column title='Protocolo' dataIndex='protocolo' key='protocolo' /> */}
                        <Column title='Status' dataIndex='status' key='status' render={
                            (status) => (
                                <Tag color='green'>{status}</Tag>
                            )
                        } />
                        <Column title='' dataIndex='id' key='id' render={
                            (status, record) => (
                                this.renderAction(status, record)
                            )
                        } />
                    </Table>
                </Card>
                <Modal title="Protocolo"
                    visible={this.state.visibleModalProtocolo}
                    onOk={this.enviarProtocolo}
                    onCancel={this.hideModal}
                    okText="Protocolar"
                    cancelText="Cancelar"
                    >
                    <Input placeholder="Protocolo" value={currentProtocolo} onChange={(value) => this.atualizarProtocolo(value)} />
                </Modal>
                <Modal title="Atenção:"
                    visible={this.state.visibleModalCancelar}
                    onOk={this.cancelarApuracao}
                    onCancel={this.hideModal}
                    okText="Cancelar balancete"
                    cancelText="Fechar"
                    >
                    <p>Deseja realmente cancelar?</p>
                </Modal>
            </Content>
        )

    }

}

export default BalanceteView
