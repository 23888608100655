import './styles.css';
import cepService from '../../services/CEP';
import cnpjService from '../../services/CNPJ';
import empresaService from '../../services/Empresa';
import municipioService from '../../services/Municipio';
import tipoArredondamentoService from '../../services/TipoArredondamento';
import tipoConsolidacaoService from '../../services/TipoConsolidacao';
import tipoEmpresaService from '../../services/TipoEmpresa';
import tipoInstituicaoService from '../../services/TipoInstituicao';
import unidadeFederativaService from '../../services/UnidadeFederativa';
import versaoConceitualService from '../../services/VersaoConceitual';
import versaoDesifService from '../../services/VersaoDesif';
import { Breadcrumb, Card, Col, Form, Icon, Input, Layout, Row, Select, Spin, Tabs, Button, notification } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import MaskedInput from 'react-text-mask';

const Content = Layout
const FormItem = Form.Item
const Option = Select
const TabPane = Tabs.TabPane

class EmpresaInput extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            disabledFields: false,
            empresa: {},
            maskedInputClass: 'ant-input',
            listEmpresaUnificadora: [],
            listaMunicipio: [],
            listaTipoArredondamento: [],
            listaTipoConsolidacao: [],
            listaTipoEmpresa: [],
            listaTipoInstituicao: [],
            listaUnidadeFederativa: [],
            listaVersaoConceitual: [],
            listaVersaoDesif: [],
            loading: false
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        const idEmpresa = this.props.match.params.id;
        if (idEmpresa && idEmpresa !== 'adicionar') {
            empresaService.findByID(idEmpresa)
            .then(response => {
                let empresa = response.data;
                if (empresa) {
                    this.setState({ disabledFields: true, empresa: empresa, maskedInputClass: 'ant-input ant-input-disabled' });
                }
            })
            .catch (error => {
                console.error(error);
                notification.error({
                    message: 'Empresa não encontrada',
                    placement: 'bottomRight'
                });
                setTimeout(function () { window.location.href = "/empresa"; }, 1500);
            })
        }
        this.getEmpresaByUsuario();
        this.getMunicipio();
        this.getTipoArredondamento();
        this.getTipoConsolidacao();
        this.getTipoEmpresa();
        this.getTipoInstituicao();
        this.getUnidadeFederatira();
        this.getVersaoConceitual();
        this.getVersaoDesif();
        this.setState({ loading: false });
    }

    getEmpresaByCNPJ(cnpj) {
        this.setState({ loading: true });
        cnpjService.findByCNPJ(cnpj)
        .then(response => {
            this.props.form.setFieldsValue({
                'nome_empresarial': response.nome,
                'nome_fantasia': response.fantasia
            });
            this.setState({ loading: false });
        })
        .catch(error => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    getEmpresaByUsuario() {
        this.setState({ loading: true });
        empresaService.findByUser()
        .then(response => {
            const lista = response.data.map((empresa) => <Option key={empresa.id} value={empresa.id}>{empresa.nome_empresarial}</Option>)
            this.setState({ listaEmpresaUnificadora: lista, loading: false });
            this.setState({ loading: false });
        })
        .catch(error => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    getEnderecoByCEP(cep) {
        this.setState({ loading: true });
        cepService.findByCEP(cep)
        .then(response => {
            var codigo_unidade_federativa = Number(this.props.form.getFieldInstance('unidade_federativa').props.children[1].filter(function (item) {
                return item.props.children === response.data.uf;
            })[0].key);
            this.props.form.setFieldsValue({
                'bairro': response.data.bairro,
                'logradouro': response.data.logradouro,
                'municipio': Number(response.data.ibge),
                'unidade_federativa': codigo_unidade_federativa
            });
            this.setState({ loading: false });
        })
        .catch(error => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    getMunicipio() {
        this.setState({ loading: true });
        municipioService.findAll()
        .then(response => {
            const lista = response.data.map((municipio) => <Option key={municipio.codigo} value={municipio.codigo}>{municipio.nome}</Option>)
            this.setState({ listaMunicipio: lista, loading: false });
        })
        .catch(error => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    getMunicipioByUnidadeFederativa(codigo) {
        this.setState({ loading: true });
        municipioService.findByCodigoUnidadeFederativa(codigo)
        .then(response => {
            const lista = response.data.map((municipio) => <Option key={municipio.codigo} value={municipio.codigo}>{municipio.nome}</Option>)
            this.setState({ listaMunicipio: lista, loading: false });
        })
        .catch(error => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    getTipoArredondamento() {
        this.setState({ loading: true });
        tipoArredondamentoService.findAll()
        .then(response => {
            const lista = response.data.map((tipo_arredondamento) => <Option key={tipo_arredondamento.id} value={tipo_arredondamento.id}>{tipo_arredondamento.nome}</Option>)
            this.setState({ listaTipoArredondamento: lista, loading: false });
        })
        .catch(error => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    getTipoConsolidacao() {
        this.setState({ loading: true });
        tipoConsolidacaoService.findAll()
        .then(response => {
            const lista = response.data.map((tipo_consolidacao) => <Option key={tipo_consolidacao.id} value={tipo_consolidacao.id}>{tipo_consolidacao.nome}</Option>)
            this.setState({ listaTipoConsolidacao: lista, loading: false });
        })
        .catch(error => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    getTipoEmpresa() {
        this.setState({ loading: true });
        tipoEmpresaService.findAll()
        .then(response => {
            const lista = response.data.map((tipo_empresa) => <Option key={tipo_empresa.id} value={tipo_empresa.id}>{tipo_empresa.nome}</Option>)
            this.setState({ listaTipoEmpresa: lista, loading: false });
        })
        .catch(error => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    getTipoInstituicao() {
        this.setState({ loading: true });
        tipoInstituicaoService.findAll()
        .then(response => {
            const lista = response.data.map((tipo_instituicao) => <Option key={tipo_instituicao.codigo} value={tipo_instituicao.codigo}>{tipo_instituicao.nome}</Option>)
            this.setState({ listaTipoInstituicao: lista, loading: false });
        })
        .catch(error => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    getUnidadeFederatira() {
        this.setState({ loading: true });
        unidadeFederativaService.findAll()
        .then(response => {
            const lista = response.data.map((unidade_federativa) => <Option key={unidade_federativa.codigo} value={unidade_federativa.codigo}>{unidade_federativa.sigla}</Option>)
            this.setState({ listaUnidadeFederativa: lista, loading: false });
        })
        .catch(error => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    getVersaoConceitual() {
        this.setState({ loading: true });
        versaoConceitualService.findAll()
        .then(response => {
            const lista = response.data.map((versao_conceitual) => <Option key={versao_conceitual.id} value={versao_conceitual.id}>{versao_conceitual.nome}</Option>)
            this.setState({ listaVersaoConceitual: lista, loading: false });
        })
        .catch(error => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    getVersaoDesif() {
        this.setState({ loading: true });
        versaoDesifService.findAll()
        .then(response => {
            const lista = response.data.map((versao_desif) => <Option key={versao_desif.id} value={versao_desif.id}>{versao_desif.nome}</Option>)
            this.setState({ listaVersaoDesif: lista, loading: false });
        })
        .catch(error => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    handleCEPBlur(event) {
        if (!this.props.form.getFieldValue('cep')) return;
        var cep = this.hasOnlyNumbers(this.props.form.getFieldValue('cep'));
        this.getEnderecoByCEP(cep);
        this.setState({ loading: true });
    }

    handleCNPJBlur(event) {
        if (!this.props.form.getFieldValue('ni')) return;
        var cnpj = this.hasOnlyNumbers(this.props.form.getFieldValue('ni'));
        this.getEmpresaByCNPJ(cnpj);
        this.setState({ loading: true });
    }

    handleUnidadeFederativaChange(event) {
        this.setState({ listaMunicipio: [], loading: true });
        this.getMunicipioByUnidadeFederativa(event);
        this.props.form.setFieldsValue({ 'municipio': '' });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        var empresa = this.props.form.getFieldsValue();
        this.setState({ loading: true });
        empresaService.save(empresa)
        .then(response => {
            this.setState({ loading: false });
            notification.success({
                message: 'Empresa adicionada com sucesso',
                placement: 'bottomRight'
            });
            setTimeout(function () { window.location.href = '/empresa'; }, 3000);
        })
        .catch(error => {
            this.setState({ loading: false });
            let message = 'Não foi possível adicionar empresa';
            if (error.response && error.response.data && error.response.data.body && typeof error.response.data.body === 'string') {
                message = error.response.data.body;
            }
            notification.error({
                message,
                placement: 'bottomRight'
            });
        });
        this.setState({ loading: false });
    }

    hasOnlyNumbers = (value) => {
        return value.replace(/[^\d]+/g, '');
    }

    render() {

        const { getFieldDecorator } = this.props.form
        const { empresa } = this.state;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        }

        return (
            <Content className='custom-content' style={{ background: '#fff', padding: '24px' }}>
                <h1>Empresa</h1>
                <Breadcrumb style={{ marginBottom: '12px' }}>
                    <Breadcrumb.Item>
                        <Icon type='home' />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to='/empresa'>Empresa</Link>
                    </Breadcrumb.Item>
                     <Breadcrumb.Item>{empresa? 'Editar' : 'Adicionar'}</Breadcrumb.Item>
                </Breadcrumb>
                <Spin spinning={this.state.loading} tip='Aguarde'>
                    <Card className='custom-card'>
                        <Form onSubmit={this.handleSubmit}>
                            <Tabs defaultActiveKey='1'>
                                <TabPane key='1' tab='Dados Cadastrais'>
                                    <Row>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label='CNPJ'>
                                                {
                                                    getFieldDecorator('ni', {
                                                        initialValue: empresa && empresa.ni ? empresa.ni : '',
                                                        rules: [{ required: true, message: 'CNPJ inválido' }]
                                                    })(
                                                        <MaskedInput
                                                            className={this.state.maskedInputClass}
                                                            disabled={this.state.disabledFields}
                                                            mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                                                            onBlur={this.handleCNPJBlur.bind(this)}
                                                        />
                                                    )
                                                }
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label='Razão Social'>
                                                {
                                                    getFieldDecorator('nome_empresarial', {
                                                        initialValue: empresa && empresa.nome_empresarial ? empresa.nome_empresarial : '',
                                                        rules: [{ required: true, message: 'Nome Empresarial inválido' }]
                                                    })(
                                                        <Input />
                                                    )
                                                }
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label='Nome Fantasia'>
                                                {
                                                    getFieldDecorator('nome_fantasia', {
                                                        initialValue: empresa && empresa.nome_fantasia ? empresa.nome_fantasia : '',
                                                        rules: [{ required: false }]
                                                    })(
                                                        <Input />
                                                    )
                                                }
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label='Inscrição Municipal'>
                                                {
                                                    getFieldDecorator('inscricao_municipal', {
                                                        initialValue: empresa && empresa.inscricao_municipal ? empresa.inscricao_municipal : '',
                                                        rules: [{ required: false }]
                                                    })(
                                                        <Input />
                                                    )
                                                }
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label='Tipo de Empresa'>
                                                {
                                                    getFieldDecorator('tipo_empresa', {
                                                        initialValue: empresa && empresa.id_tipo_empresa ? empresa.id_tipo_empresa : '',
                                                        rules: [{ required: true, message: 'Tipo de Empresa inválido' }]
                                                    })(
                                                        <Select>
                                                            <Option value=''></Option>
                                                            {this.state.listaTipoEmpresa}
                                                        </Select>
                                                    )
                                                }
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label='Tipo Instituição'>
                                                {
                                                    getFieldDecorator('tipo_instituicao', {
                                                        initialValue: empresa && empresa.codigo_tipo_instituicao ? empresa.codigo_tipo_instituicao : '',
                                                        rules: [{ required: true, message: 'Tipo de Instituição inválido' }]
                                                    })(
                                                        <Select>
                                                            <Option value=''></Option>
                                                            {this.state.listaTipoInstituicao}
                                                        </Select>
                                                    )
                                                }
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label='Tipo de Consolidação'>
                                                {
                                                    getFieldDecorator('tipo_consolidacao', {
                                                        initialValue: empresa && empresa.id_tipo_consolidacao ? empresa.id_tipo_consolidacao : '',
                                                        rules: [{ required: true, message: 'Tipo de Consolidação inválido' }]
                                                    })(
                                                        <Select>
                                                            <Option value=''></Option>
                                                            {this.state.listaTipoConsolidacao}
                                                        </Select>
                                                    )
                                                }
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label='Tipo de Arredondamento'>
                                                {
                                                    getFieldDecorator('tipo_arredondamento', {
                                                        initialValue: empresa && empresa.id_tipo_arredondamento ? empresa.id_tipo_arredondamento : '',
                                                        rules: [{ required: true, message: 'Tipo de Arredondamento inválido' }]
                                                    })(
                                                        <Select>
                                                            <Option value=''></Option>
                                                            {this.state.listaTipoArredondamento}
                                                        </Select>
                                                    )
                                                }
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label='Versão Conceitual'>
                                                {
                                                    getFieldDecorator('versao_conceitual', {
                                                        initialValue: empresa && empresa.id_versao_conceitual ? empresa.id_versao_conceitual : '',
                                                        rules: [{ required: true, message: 'Versão Conceitual inválida' }]
                                                    })(
                                                        <Select>
                                                            <Option value=''></Option>
                                                            {this.state.listaVersaoConceitual}
                                                        </Select>
                                                    )
                                                }
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label='Versão DES-IF'>
                                                {
                                                    getFieldDecorator('versao_desif', {
                                                        initialValue: empresa && empresa.id_versao_desif ? empresa.id_versao_desif : '',
                                                        rules: [{ required: true, message: 'Versão DES-IF inválida' }]
                                                    })(
                                                        <Select>
                                                            <Option value=''></Option>
                                                            {this.state.listaVersaoDesif}
                                                        </Select>
                                                    )
                                                }
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label='Empresa Unificadora'>
                                                {
                                                    getFieldDecorator('empresa_unificadora', {
                                                        initialValue: empresa && empresa.id_empresa_unificadora ? empresa.id_empresa_unificadora : '',
                                                        rules: [{ required: false }]
                                                    })(
                                                        <Select>
                                                            <Option value=''></Option>
                                                            {this.state.listaEmpresaUnificadora}
                                                        </Select>
                                                    )
                                                }
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane key='2' tab='Endereço'>
                                    <Row>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label='CEP'>
                                                {
                                                    getFieldDecorator('cep', {
                                                        initialValue: empresa && empresa.cep ? empresa.cep : '',
                                                        rules: [{ required: true, message: 'CEP inválido' }]
                                                    })(
                                                        <MaskedInput
                                                            className={this.state.maskedInputClass}
                                                            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                                                            onBlur={this.handleCEPBlur.bind(this)}
                                                        />
                                                    )
                                                }
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label='Logradouro'>
                                                {
                                                    getFieldDecorator('logradouro', {
                                                        initialValue: empresa && empresa.logradouro ? empresa.logradouro : '',
                                                        rules: [{ required: true, message: 'Logradouro inválido' }]
                                                    })(
                                                        <Input />
                                                    )
                                                }
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label='Número'>
                                                {
                                                    getFieldDecorator('numero', {
                                                        initialValue: empresa && empresa.numero ? empresa.numero : '',
                                                        rules: [{ required: false }]
                                                    })(
                                                        <Input />
                                                    )
                                                }
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label='Complemento'>
                                                {
                                                    getFieldDecorator('complemento', {
                                                        initialValue: empresa && empresa.complemento ? empresa.complemento : '',
                                                        rules: [{ required: false }]
                                                    })(
                                                        <Input />
                                                    )
                                                }
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label='Bairro'>
                                                {
                                                    getFieldDecorator('bairro', {
                                                        initialValue: empresa && empresa.bairro ? empresa.bairro : '',
                                                        rules: [{ required: true, message: 'Bairro inválido' }]
                                                    })(
                                                        <Input />
                                                    )
                                                }
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label='Unidade Federativa'>
                                                {
                                                    getFieldDecorator('unidade_federativa', {
                                                        initialValue: empresa && empresa.codigo_unidade_federativa ? empresa.codigo_unidade_federativa : '',
                                                        rules: [{ required: true, message: 'Unidade Federativa inválida' }]
                                                    })(
                                                        <Select onSelect={this.handleUnidadeFederativaChange.bind(this)}>
                                                            <Option value=''></Option>
                                                            {this.state.listaUnidadeFederativa}
                                                        </Select>
                                                    )
                                                }
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label='Município'>
                                                {
                                                    getFieldDecorator('municipio', {
                                                        initialValue: empresa && empresa.codigo_municipio ? empresa.codigo_municipio : '',
                                                        rules: [{ required: true, message: 'Município inválido' }]
                                                    })(
                                                        <Select disabled={this.state.disabledFields}>
                                                            <Option value=''></Option>
                                                            {this.state.listaMunicipio}
                                                        </Select>
                                                    )
                                                }
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem {...formItemLayout} label='Telefone'>
                                                {
                                                    getFieldDecorator('telefone', {
                                                        initialValue: empresa && empresa.telefone ? empresa.telefone : '',
                                                        rules: [{ required: true, message: 'Telefone inválido' }]
                                                    })(
                                                        <MaskedInput
                                                            className={this.state.maskedInputClass}
                                                            mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                        />
                                                    )
                                                }
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </Tabs>
                            <FormItem>
                                <Button type='primary' icon='save' htmlType='submit'>Salvar</Button>
                            </FormItem>
                        </Form>
                    </Card>
                </Spin>
            </Content>
        )

    }

}

const WrappedNormalLoginForm = Form.create()(EmpresaInput);

export default WrappedNormalLoginForm;
