import './styles.css'
import { Icon, Layout, Menu } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const { Sider } = Layout

const CustomSider = () => {
    const perfil = window.localStorage.clientProfile
    return (
        <Sider className='custom-sider' width={250}>
            <Menu className='custom-menu' mode='inline'>
                <Menu.Item key='dashboard' disabled><Link to='/dashboard'><Icon type='dashboard' />Dashboard</Link></Menu.Item>
                <Menu.Divider />
                <Menu.Item key='empresa'><Link to='/empresa'><Icon type='bank' />Empresa</Link></Menu.Item>
                <Menu.Item key='plano-conta'><Link to='/plano-conta'><Icon type='profile' />Plano de Conta</Link></Menu.Item>
                <Menu.Item key='tarifa'><Link to='/tarifa'><Icon type='bars' />Tarifa</Link></Menu.Item>
                <Menu.Item key='servicos' disabled><Link to='/servicos'><Icon type='bars' />Serviços</Link></Menu.Item>
                <Menu.Divider />
                <Menu.Item key='balancete'><Link to='/balancete'><Icon type='read' />Balancete</Link></Menu.Item>
                <Menu.Item key='apuracao'><Link to='/apuracao-mensal-issqn'><Icon type='reconciliation' />Apuração Mensal ISSQN</Link></Menu.Item>
                <Menu.Item key='infocom'><Link to='/informacoes-comuns'><Icon type='info-circle' />Informações Comuns</Link></Menu.Item>
                <Menu.Item key='demonstativo'><Link to='/demonstrativo-contabil'><Icon type='file-text' />Demonstrativo Contábil</Link></Menu.Item>
                <Menu.Divider />
                {
                    perfil === 'Master' && <Menu.Item key='usuario'><Link to='/usuario'><Icon type='user' />Usuário</Link></Menu.Item>
                }
                <Menu.Item key='sair'><Link to='/sair'><Icon type='logout' />Sair</Link></Menu.Item>
            </Menu>
        </Sider>
    )
}

export default CustomSider
