import Http from '../utils/Http';

export default {

    findAll() {
        return Http.get_fixed('/plano-conta');
    },

    
    findByID(id) {
        return Http.get_fixed(`/plano-conta/${id}`);
    },

    save(data) {
        return Http.post_fixed('/plano-conta', data);
    },

    update(id, data) {
        return Http.put_fixed(`/plano-conta/${id}`, data);
    },

    delete(id) {
        return Http.delete_fixed(`/plano-conta/${id}`);
    },

}
