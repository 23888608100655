import App from './App'
import React from 'react'
import ReactDOM from 'react-dom'
import WrappedNormalLoginForm from './components/Login'

class ApplicationWrapper extends React.Component {

    constructor() {
        super()
        this.state = {
            initialized: true,
            isForbbiden: false,
            message: ''
        }
    }

    componentWillMount(){
        const token = window.localStorage.clientToken
        if (!token) {
            this.setState({ initialized: false })
        }
    }

    render() {
        if (this.state.initialized) {
            return <App />
        }else {
            return <WrappedNormalLoginForm />
        }
    }

}

ReactDOM.render(<ApplicationWrapper />, document.getElementById('root'))
