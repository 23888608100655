import './styles.css'
import { Divider } from 'antd'
import React from 'react'

const Footer = () => (
    <div className='footer'>
        <Divider />
        <h6 className='description'>Copyright © 2019 iKeep. Todos os direitos reservados.</h6>
    </div>
)

export default Footer
