import Http from '../utils/Http';

export default {

    getAll() {
        return Http.get_fixed('/infocom');
    },
    
    
    // get(id) {
    //     return Http.get_fixed(`/infocom/${id}`);
    // },

    save(data) {
        return Http.post_fixed('/infocom', data);
    },

    // update(id, data) {
    //     return Http.put_fixed(`/infocom/${id}`, data);
    // },

    cancel(id) {
        return Http.delete_fixed(`/infocom/${id}`);
    },

    download(id) {
        return Http.download(`/infocom/gerar/${id}`);
    },

    setProtocol(id, data) {
        return Http.put_fixed(`/infocom/protocolo/${id}`, data);
    },

    setRectification(id, data) {
        return Http.put_fixed(`/infocom/retificao/${id}`, data);
    },

}
