

import React from 'react';
import 'antd/dist/antd.css';
import './styles.css';
import Http from '../../utils/Http'
import generalService from '../../services/General';
import {
  Form, Icon, Input, Button, message
} from 'antd';

class NormalLoginForm extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        Http.post("/login", values)
        .then(response => {
          console.debug('login::post response: start')
          window.clearTimeout(1800)
          window.localStorage.setItem('clientProfile', response.data.perfil)
          window.localStorage.setItem('clientToken', response.data.token)

          console.debug('login::before download', window.localStorage.getItem('general'))
          generalService.downloadData().then(data => {
            console.debug('login::before redirect', window.localStorage.getItem('general'))
            window.location.href = "/empresa"
            console.debug('login::after redirect', window.localStorage.getItem('general'))
          }).catch(err => {
            message.error('Erro ao carregar informações gerais')
            console.error('login::error downloading')
          })
          console.debug('login::after download', window.localStorage.getItem('general'))
          console.debug("login::post response: end")
        })
        .catch(err => {
              this.setState({
                  empresas: []
              });
              message.error('Usuário e/ou senha inválido.')
              console.error('Não foi possível fazer o login.', err)
        })
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
      <div style={{height: 120, width: '100%'}}>
        <div style={{marginLeft: 70}}>
              <img alt='iDesif' className='logo' src={require('../../assets/logo.png')} />
        </div>
      </div>
        <Form.Item>
          {getFieldDecorator('userName', {
            rules: [{ required: true, message: 'Usuário inválido.' }],
          })(
            <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="E-mail" />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Senha inválida.' }],
          })(
            <Input autoComplete="password" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Senha" />
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Entrar
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);

// ReactDOM.render(<WrappedNormalLoginForm />, document.getElementById('container'));


export default WrappedNormalLoginForm
