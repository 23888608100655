import './App.css'
import BalanceteInput from './components/BalanceteInput'
import BalanceteView from './components/BalanceteView'
import ApuracaoMensalISSQNView from './components/ApuracaoMensalISSQNView'
import DemonstrativoContabilInput from './components/DemonstrativoContabilInput'
import EmpresaEdit from './components/EmpresaEdit'
import EmpresaInput from './components/EmpresaInput'
import EmpresaView from './components/EmpresaView'
import Footer from './components/Footer'
import Header from './components/Header'
import InformacoesComunsInput from './components/InformacoesComunsInput'
import Layout from './components/Layout'
import PlanoContaImport from './components/PlanoContaImport'
import PlanoContaInput from './components/PlanoContaInput'
import PlanoContaView from './components/PlanoContaView'
import TarifaInput from './components/TarifaInput'
import TarifaView from './components/TarifaView'
import UsuarioView from './components/UsuarioView'
import UsuarioInput from './components/UsuarioInput'
import Sider from './components/Sider'
import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

class App extends React.Component {

    render() {
        return (
            <div className='app'>
                <BrowserRouter>
                    <Layout>
                        <Header />
                        <Layout>
                            <Sider />
                            <Layout id='layout-content-footer'>
                                <Switch>
                                    <Route exact path='/empresa' component={EmpresaView} />
                                    <Route exact path='/empresa/:id' component={EmpresaEdit} />
                                    <Route exact path='/empresa/adicionar' component={EmpresaInput} />
                                    <Route exact path='/tarifa' component={TarifaView} />
                                    <Route exact path='/tarifa/adicionar' component={TarifaInput} />
                                    <Route exact path='/tarifa/:id' component={TarifaInput} />
                                    <Route exact path='/plano-conta' component={PlanoContaView} />
                                    <Route exact path='/plano-conta/importacao' component={PlanoContaImport} />
                                    <Route exact path='/plano-conta/adicionar' component={PlanoContaInput} />
                                    <Route exact path='/plano-conta/:id' component={PlanoContaInput} />
                                    <Route exact path='/apuracao-mensal-issqn' component={ApuracaoMensalISSQNView} />
                                    <Route exact path='/balancete' component={BalanceteView} />
                                    <Route exact path='/balancete/cadastrar' component={BalanceteInput} />
                                    <Route exact path='/informacoes-comuns' component={InformacoesComunsInput} />
                                    <Route exact path='/demonstrativo-contabil' component={DemonstrativoContabilInput} />
                                    <Route exact path='/usuario' component={UsuarioView} />
                                    <Route exact path='/usuario/adicionar' component={UsuarioInput} />
                                    <Route exact={true} path="/sair" render={()=>{ window.localStorage.clear(); window.location.reload();}}  />
                                </Switch>
                            </Layout>
                        </Layout>
                        <Footer />
                    </Layout>
                </BrowserRouter>
            </div>
        )
    }

}

export default App
