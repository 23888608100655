import axios from 'axios-jsonp-pro';

const apiURL = 'https://www.receitaws.com.br/v1/cnpj/';

class HttpReceitaWS {

    get(cnpj) {
        return axios.jsonp(apiURL + cnpj);
    }

}

export default new HttpReceitaWS();
