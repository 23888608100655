/* eslint-disable no-unused-vars */
import React from 'react'
import {message, Form, Select} from 'antd'
import genericServiceMaker from '../../services/Service'
const service = genericServiceMaker('empresa')

// const FormItem = Form.Item
const Option = Select

class EmpresaSelector extends React.Component {
    state = {
        records: [],
        idEmpresa: "",
        loading: false
    }

    componentDidMount(){
        this.getAll();
    }

    getAll = async () => {
        this.setState({loading: true})
        try {
            let reponse = await service.getAll()
            this.setState({records: reponse.data, loading: false})
        }
        catch(err) {
            this.setState({records: [], loading: false});
            message.error('Não foi possível obter as empresas.');
            console.error('Não foi possível obter as empresas.', err)
        }
    }

    setIds = value => {
        this.setState({idEmpresa: value});
        if (this.props.onChange)
            this.props.onChange(value)
    }

    getIds = () => this.state.idEmpresa


    render() {
        const {records, loading} = this.state
        const style = {width:'100%', ...this.props.style}

        return (
            // <FormItem label='Empresa'>
                <Select
                    mode="multiple"
                    loading={loading || !records}
                    style={style}
                    onChange={this.setIds}
                >
                {
                    !records? null : records.map( empresa =>
                        <Option key={empresa.id} value={empresa.id}>
                            {empresa.nome_fantasia} - {empresa.ni}
                        </Option>
                    )
                }
                </Select>
            // </FormItem>
        )

    }

}

export default EmpresaSelector
