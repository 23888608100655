import Http from '../utils/Http'

export default {

    save(params) {
        return Http.post('/usuario', params);
    },

    deleteUserById(id) {
        var params = { 'id': id }
        return Http.delete('/usuario/', params);
    },

    findAll() {
        return Http.get('/usuario')
    },

    findByID(id) {
        return Http.get('/usuario/' + id)
    }

}
